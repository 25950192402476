import React, { ReactNode } from 'react';

import PropTypes from 'prop-types';

import Illustration, { IllustrationType } from '@adsk/alloy-react-illustration';
import theme, {
  stylePropType,
  StylableComponent,
} from '@adsk/alloy-react-theme';

const SIZES = {
  MEDIUM: 'medium',
  LARGE: 'large',
} as const;

type Sizes = typeof SIZES;
export type Size = Sizes[keyof Sizes];

type EmptyStateProps = Omit<StylableComponent<HTMLDivElement>, 'title'> & {
  title?: ReactNode;
  illustrationType?: IllustrationType;
  description?: ReactNode;
  hideIllustration?: boolean;
  size?: Size;
};

/**
 * An EmptyState
 * Empty states occur when data is not available to be displayed.
 */
const EmptyState = React.forwardRef<HTMLDivElement, EmptyStateProps>(
  (
    {
      style,
      className,
      title,
      illustrationType,
      description,
      children,
      hideIllustration,
      size,
      ...props
    },
    ref,
  ) => {
    const showIllustration = !hideIllustration && illustrationType;
    const illustrationStyle =
      size === SIZES.LARGE ? { height: 250, width: 250 } : undefined;

    return (
      <div
        {...props}
        className={className}
        ref={ref}
        css={[
          {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: theme.tokens.colors.background.primary.value,
            color: theme.tokens.colors.text.primary.value,
            boxSizing: 'border-box',
            padding: '20px 40px',
            ...(!showIllustration && {
              '> div:first-child': {
                marginTop: 0,
              },
            }),
          },
          style,
        ]}
      >
        {showIllustration && (
          <Illustration type={illustrationType} style={illustrationStyle} />
        )}
        {title && (
          <div
            css={[
              {
                marginTop: theme.spacing.L,
              },
              size === SIZES.LARGE
                ? theme.typography.title
                : theme.typography.heading2,
            ]}
          >
            {title}
          </div>
        )}
        {description && (
          <div
            css={[
              {
                marginTop: theme.spacing.XS,
                color: theme.tokens.colors.text.secondary.value,
                ...theme.typography.bodyMedium,
              },
            ]}
          >
            {description}
          </div>
        )}
        {children && (
          <div
            css={[
              {
                marginTop: theme.spacing.L,
              },
            ]}
          >
            {children}
          </div>
        )}
      </div>
    );
  },
);

EmptyState.displayName = 'EmptyState';

EmptyState.defaultProps = {
  hideIllustration: false,
  illustrationType: Illustration.TYPES.FOLDER_EMPTY_GREY,
  size: SIZES.MEDIUM,
};

EmptyState.propTypes = {
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,
  /** Empty state title (optional) */
  title: PropTypes.any,
  /** Illustration type */
  illustrationType: PropTypes.oneOf(Object.values(Illustration.TYPES)),
  /** State description */
  description: PropTypes.any,
  /** Additional state content */
  children: PropTypes.any,
  /** Wheteher we should hide the default illustration */
  hideIllustration: PropTypes.bool,
  /** EmptyState size */
  size: PropTypes.oneOf(Object.values(SIZES)),
};

export default Object.assign(EmptyState, {
  ILLUSTRATION_TYPES: Illustration.TYPES,
  SIZES,
});
