import React, { forwardRef, ComponentProps } from 'react';

import Icon, { IconWithRender, generateRenderedIcon } from './Icon';
import { VIEWBOXES } from '../consts';
import { IconVariations } from '../useIconVariation';

const draw16px =
  'M6.18 2v3.42a.75.75 0 0 1-.75.75H2a.74.74 0 0 1-.69-.46.76.76 0 0 1 .16-.82L4.9 1.47A.75.75 0 0 1 6.18 2Zm8.35 2.89L11.1 1.47a.74.74 0 0 0-.81-.17.76.76 0 0 0-.47.7v3.42a.75.75 0 0 0 .75.75H14a.73.73 0 0 0 .69-.46.74.74 0 0 0-.16-.82Zm.16 5.39a.73.73 0 0 0-.69-.46h-3.43a.75.75 0 0 0-.75.75V14a.76.76 0 0 0 .47.69.71.71 0 0 0 .28.06.75.75 0 0 0 .53-.22l3.43-3.43a.74.74 0 0 0 .16-.82Zm-9.26-.46H2a.74.74 0 0 0-.69.46.76.76 0 0 0 .16.82l3.43 3.43A.75.75 0 0 0 6.18 14v-3.43a.75.75 0 0 0-.75-.75ZM8 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2Z';
const draw24px =
  'M9.75,15v4a.75.75,0,0,1-1.5,0V16.81L4.53,20.53a.74.74,0,0,1-.53.22.71.71,0,0,1-.53-.22.74.74,0,0,1,0-1.06l3.72-3.72H5a.75.75,0,0,1,0-1.5H9a.72.72,0,0,1,.29.06.74.74,0,0,1,.4.4A.72.72,0,0,1,9.75,15ZM9,4.25A.75.75,0,0,0,8.25,5V7.19L4.53,3.47a.75.75,0,0,0-1.06,0,.74.74,0,0,0,0,1.06L7.19,8.25H5a.75.75,0,0,0,0,1.5H9a.74.74,0,0,0,.53-.22.78.78,0,0,0,.16-.24A.72.72,0,0,0,9.75,9V5A.76.76,0,0,0,9,4.25Zm11.53-.78a.75.75,0,0,0-1.06,0L15.75,7.19V5a.75.75,0,0,0-1.5,0V9a.82.82,0,0,0,.05.28.85.85,0,0,0,.17.25.75.75,0,0,0,.38.21H19a.75.75,0,0,0,0-1.5H16.81l3.72-3.72A.75.75,0,0,0,20.53,3.47ZM14.25,8.86a.75.75,0,0,0,.05.42A.82.82,0,0,1,14.25,9Zm.6.88-.2-.08Zm-.38-.21.18.13A.83.83,0,0,1,14.47,9.53Zm6.06,9.94-3.72-3.72H19a.75.75,0,0,0,0-1.5H15a.72.72,0,0,0-.29.06.78.78,0,0,0-.24.16.76.76,0,0,0-.21.38c0,.05,0,.1,0,.15v4a.75.75,0,0,0,1.5,0V16.81l3.72,3.72a.71.71,0,0,0,.53.22.74.74,0,0,0,.53-.22A.75.75,0,0,0,20.53,19.47ZM14.25,15c0-.05,0-.1,0-.15a1,1,0,0,0,0,.3ZM12,10a2,2,0,1,0,2,2A2,2,0,0,0,12,10Z';

const icons: IconVariations = {
  _16: { d: draw16px, viewBox: VIEWBOXES._16 },
  _24: { d: draw24px, viewBox: VIEWBOXES._24 },
};

const CollapseIcon = forwardRef<SVGSVGElement, ComponentProps<typeof Icon>>(
  generateRenderedIcon(icons, 'collapse'),
) as IconWithRender;

CollapseIcon.displayName = 'CollapseIcon';
CollapseIcon.propTypes = Icon.propTypes;
CollapseIcon.renderIcon = (props) => <CollapseIcon {...props} />;
export default CollapseIcon;
