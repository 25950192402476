// called from index.ts

export enum JsonType {
  Flat = 'flat',
  Meta = 'meta',
  Nested = 'nested',
  Default = Nested,
}

export const jsonTypes = [JsonType.Flat, JsonType.Meta, JsonType.Nested];
