import React, {
  ComponentProps,
  ComponentPropsWithRef,
  ReactElement,
  Ref,
  RefObject,
} from 'react';

import PropTypes from 'prop-types';

import Button from '@adsk/alloy-react-button';
import { useControlled, pickKeys } from '@adsk/alloy-react-helpers';
import { MoreHorizontalIcon, MoreVerticalIcon } from '@adsk/alloy-react-icon';
import Overlay from '@adsk/alloy-react-overlay';
import theme, { StyleProp, stylePropType } from '@adsk/alloy-react-theme';

import { menuItemShape, menuCategoryShape } from '../helpers/propTypes';
import { MenuCategoryType, MenuItemType, SharedProps } from '../helpers/types';
import Menu from '../Menu';

type ActionButtonProps<TKey extends string | number> = Omit<
  ComponentProps<typeof Button>,
  'defaultValue' | 'value' | 'onChange'
> &
  Pick<
    ComponentProps<typeof Overlay>,
    'container' | 'placement' | 'show' | 'overlayRef'
  > &
  SharedProps<TKey> & {
    horizontal?: boolean;
    options?: (MenuItemType<TKey> | MenuCategoryType<TKey>)[];
    renderContent?: (
      props: ComponentPropsWithRef<typeof Menu<TKey>>,
    ) => ReactElement;
    menuStyle?: StyleProp;
  };
/**
 * Button component with Menu
 */
const ActionButtonWrapped = <TKey extends string | number>(
  {
    className,
    style,
    disabled,
    horizontal = true,
    options = [],
    renderContent = (p) => <Menu {...p} />,
    defaultValue = [],
    value,
    onClick,
    onChange,
    show: showProp,
    placement: placementProp = Overlay.PLACEMENTS.BOTTOM_START,
    container = document.body,
    menuStyle = { width: 200 },
    overlayRef,
    ...props
  }: ActionButtonProps<TKey>,
  ref: Ref<HTMLButtonElement>,
) => {
  const [selectedValue, setSelectedValue] = useControlled<TKey[]>(
    defaultValue,
    value,
    onChange,
  );

  return (
    <Overlay<HTMLButtonElement>
      show={showProp}
      target={ref as RefObject<HTMLButtonElement>}
      overlayRef={overlayRef}
      placement={placementProp}
      container={container}
      renderTarget={({ targetProps, show, handleToggle }) => (
        <Button
          className={className}
          disabled={disabled}
          renderIcon={(p) =>
            horizontal ? (
              <MoreHorizontalIcon {...p} />
            ) : (
              <MoreVerticalIcon {...p} />
            )
          }
          onClick={(...args) => {
            if (onClick) onClick(...args);
            handleToggle();
          }}
          style={[
            {
              backgroundColor: show
                ? theme.tokens.colors.background.neutral.selected.value
                : theme.tokens.colors.background.primary.value,
            },
            disabled && {
              '&:active': {
                borderColor: theme.tokens.colors.border.inactive.value,
              },
            },
            style,
          ]}
          {...props}
          {...targetProps}
        />
      )}
      renderOverlay={({ overlayProps, placement, handleHide }) =>
        renderContent({
          'data-testid': 'ActionButton__Menu',
          ref: overlayProps.ref,
          placement,
          options,
          defaultValue,
          value: selectedValue,
          onChange: setSelectedValue,
          onClose: handleHide,
          style: [menuStyle, overlayProps.style],
        })
      }
    />
  );
};

const ActionButton = React.forwardRef(ActionButtonWrapped);

ActionButton.displayName = 'ActionButton';

ActionButton.propTypes = {
  ...pickKeys<Record<string, unknown>>(Overlay.propTypes, [
    'container',
    'placement',
    'show',
    'overlayRef',
  ]),
  /** Options for the action */
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  options: PropTypes.arrayOf<any>(
    PropTypes.oneOfType([menuItemShape, menuCategoryShape]),
  ).isRequired,
  /** Disable button with horizontal or vertical dots*/
  horizontal: PropTypes.bool,
  /** Disable button functionality */
  disabled: PropTypes.bool,
  /** handle onClick event for button */
  onClick: PropTypes.func,
  /** render custom menu content, called with args ({ ref, placement, options, defaultValue, value, onChange, onClose, style }) */
  renderContent: PropTypes.func,
  /** Styles applied to the overlay element */
  menuStyle: stylePropType,
};

export default Object.assign(
  ActionButton as <TKey extends string | number>(
    p: ActionButtonProps<TKey> & { ref?: Ref<HTMLDivElement> },
  ) => ReactElement,
  {
    VARIANTS: Button.VARIANTS,
    SIZES: Button.SIZES,
    PLACEMENTS: Overlay.PLACEMENTS,
  },
);
