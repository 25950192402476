import React, { ChangeEvent, InputHTMLAttributes } from 'react';

import PropTypes from 'prop-types';

import { useControlled } from '@adsk/alloy-react-helpers';
import {
  CheckmarkIcon,
  MinusIcon,
  RenderIconType,
} from '@adsk/alloy-react-icon';
import theme, {
  getInteractiveStyles,
  stylePropType,
  StylableComponent,
} from '@adsk/alloy-react-theme';

export type Indeterminate = 'indeterminate';
export type CheckboxState = true | false | Indeterminate;

const CONTROL_SIZE = 16 as const;
const CONTROL_BORDER_WIDTH = 1 as const;
const CLICK_TARGET_SIZE = 20 as const;
const CLICK_TARGET_OFFSET = (CONTROL_SIZE - CLICK_TARGET_SIZE) / 2;

const INDETERMINATE = 'indeterminate' as const;

const CheckboxStates = [true, false, INDETERMINATE] as const;

const mapStateToAriaChecked = (state: CheckboxState) => {
  if (state === INDETERMINATE) return 'mixed';
  return state;
};

const renderCheckmarkIcon: RenderIconType = (p) => <CheckmarkIcon {...p} />;
const renderMinusIcon: RenderIconType = (p) => <MinusIcon {...p} />;

const mapStateToIconRenderer = (state: CheckboxState) => {
  if (state === true) return renderCheckmarkIcon;
  if (state === INDETERMINATE) return renderMinusIcon;
  return () => null;
};

const checkIndeterminateState = (state: CheckboxState) => {
  // Expected behavior when using indeterminate checkbox state.
  if (state === INDETERMINATE) {
    return true;
  }

  return !state;
};

export type CheckboxProps = Omit<
  StylableComponent<HTMLDivElement>,
  'defaultChecked' | 'onChange'
> & {
  checked?: CheckboxState;
  defaultChecked?: CheckboxState;
  disabled?: boolean;
  id?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  name?: string;
  onChange?: (
    state: CheckboxState,
    event: ChangeEvent<HTMLInputElement>,
  ) => void;
  readOnly?: boolean;
  'data-testid'?: string;
};

/**
 * A Checkbox
 * Provides a control to select from a list of non-exclusive options.
 * Checking the box should enable or select the active state.
 * (Don't phrase check boxes with a negative and require checking a box to turn something off.)
 * The rest of the props are propogated to the input element.
 */
const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      style,
      className,
      id,
      name,
      checked,
      disabled = false,
      defaultChecked = false,
      onChange: onChangeProp,
      readOnly,
      inputProps = {},
      'data-testid': dataTestid,
      ...props
    },
    ref,
  ) => {
    const [value, handleOnChange] = useControlled<CheckboxState>(
      defaultChecked,
      checked,
      onChangeProp,
    );

    const onChangeWrapper = (e: ChangeEvent<HTMLInputElement>) =>
      handleOnChange(checkIndeterminateState(value), e);

    const onChange = readOnly
      ? undefined
      : (e: ChangeEvent<HTMLInputElement>) => {
          !disabled && onChangeWrapper(e);
        };

    const notUnchecked = value !== false;

    const { style: inputStyle, ...rest } = inputProps;

    return (
      <div
        role="checkbox"
        aria-checked={mapStateToAriaChecked(value)}
        className={className}
        css={[
          {
            display: 'block',
            height: CONTROL_SIZE,
            width: CONTROL_SIZE,
            borderWidth: notUnchecked ? 0 : CONTROL_BORDER_WIDTH,
            borderStyle: 'solid',
            borderRadius: theme.borderRadius.small,
            borderColor: theme.tokens.colors.border.value,
            backgroundColor: notUnchecked
              ? theme.tokens.colors.background.selected.value
              : 'inherit',
            cursor: 'pointer',
            color: theme.tokens.colors.icon.inverse.value,
            ...getInteractiveStyles(disabled),
            ...(disabled && {
              backgroundColor: notUnchecked
                ? theme.tokens.colors.background.neutral.inactive.value
                : 'inherit',
              borderColor: theme.tokens.colors.border.inactive.value,
              color: theme.tokens.colors.icon.inactive.value,
            }),
            '& svg': {
              display: 'block',
            },
            pointerEvents: disabled ? 'none' : 'auto',
          },
          style,
        ]}
        {...props}
      >
        {notUnchecked && mapStateToIconRenderer(value)({ size: CONTROL_SIZE })}
        <input
          ref={ref}
          id={id}
          name={name}
          value={value.toString()}
          type="checkbox"
          onChange={onChange}
          readOnly={readOnly}
          checked={!!value}
          disabled={disabled}
          data-testid={dataTestid}
          css={[
            {
              // pointer-events needs to be 'none' when disabled for a wrapping
              // Tooltip to disappear correctly: https://github.com/react-component/tooltip/issues/18#issuecomment-411476678
              pointerEvents: disabled ? 'none' : 'auto',
              appearance: 'none',
              cursor: 'inherit',
              display: 'block',
              position: 'relative',
              opacity: 0,
              width: CLICK_TARGET_SIZE,
              height: CLICK_TARGET_SIZE,
              top: notUnchecked
                ? CLICK_TARGET_OFFSET - CONTROL_SIZE
                : CLICK_TARGET_OFFSET - CONTROL_BORDER_WIDTH,
              left: notUnchecked
                ? CLICK_TARGET_OFFSET
                : CLICK_TARGET_OFFSET - CONTROL_BORDER_WIDTH,
              margin: 0,
              padding: 0,
            },
            { ...inputStyle },
          ]}
          {...rest}
        />
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,
  /** The id of the checkbox, used for the label */
  id: PropTypes.string,
  /** The name for the checkbox input */
  name: PropTypes.string,
  /** The value of the checkbox */
  checked: PropTypes.oneOf(CheckboxStates),
  /** The initial value of the checkbox, this will be read only if `checked` is undefined */
  defaultChecked: PropTypes.oneOf(CheckboxStates),
  /** onChange handler for the checkbox input */
  onChange: PropTypes.func,
  /** Disables propagation stop */
  readOnly: PropTypes.bool,
  /** Disable checkbox functionallity */
  disabled: PropTypes.bool,
  /** Props passed to the input element */
  inputProps: PropTypes.object,
  /** data-testid passed to the input element */
  'data-testid': PropTypes.string,
};

export default Object.assign(Checkbox, { INDETERMINATE });
