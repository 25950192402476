import React, { Dispatch, useReducer, useState, ReactNode } from 'react';
import { SizeMeProps } from 'react-sizeme';
import { getOrCreateGlobalContext } from '@adsk/alloy-react-context';
import {
  CardComponentProps,
  CardConfig,
  GridSettingsType,
  InitialCardLayoutType,
  LocalCardComponents,
  OnSaveFunction,
  OnNewlyAddedCardsFunction,
} from './types';
import {
  CardGridActionTypes,
  CardGridState,
  initialCardGridState,
  reducer,
} from './cardGridSlice';
import CustomizableGrid from './CustomizableGrid';
import { CARD_GRID_CONTEXT } from './constants';
import {
  buildCardSettingsFromLayout,
  formatInitialCardLayout,
} from './helpers';

type CardGridContextType = {
  dispatch: Dispatch<CardGridActionTypes>;
  onSave?: OnSaveFunction;
  size: SizeMeProps['size'] | Record<string, never>;
  state: CardGridState;
};

export interface CardGridProps {
  /** The available cards to add and render on the grid */
  availableCards: CardConfig[];
  /** The initial layout of the cards on the grid */
  initialCardLayout?: InitialCardLayoutType[];
  /** An object mapping the available cards to their React component */
  localCardComponents: LocalCardComponents;
  /** A callback when saving the card layout */
  onSave?: OnSaveFunction;
  /** A react element to render when card  is not 
   * found in localCardComponents */
  renderOnCardNotFound?: () => ReactNode;
  /** Renders a wrapper for cards */
  renderCardWrapper?: React.FC<CardComponentProps>;
  /** Grid settings */
  gridSettings?: GridSettingsType;
  /** A callback when new cards are added to the layout */
  onNewlyAddedCards?: OnNewlyAddedCardsFunction;
  children?: ReactNode;
}

export const CardGridContext = getOrCreateGlobalContext<CardGridContextType>(
  CARD_GRID_CONTEXT
);

const CardGrid: React.FC<CardGridProps> = ({
  availableCards,
  initialCardLayout = [],
  localCardComponents,
  onSave,
  children,
  renderOnCardNotFound,
  renderCardWrapper,
  gridSettings,
  onNewlyAddedCards,
}) => {
  const formattedInitialCardLayout = formatInitialCardLayout(initialCardLayout);
  const initialState: CardGridState = {
    ...initialCardGridState,
    allLayouts: { desktop: formattedInitialCardLayout },
    availableCards,
    cardSettings: buildCardSettingsFromLayout(formattedInitialCardLayout),
    initialDesktopLayout: formattedInitialCardLayout,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [size, setSize] = useState<SizeMeProps['size']>({
    height: null,
    width: null,
  });
  const onSize = (size: SizeMeProps['size']) => setSize(size);
  const value = {
    dispatch,
    onSave,
    size,
    state,
  };

  return (
    <CardGridContext.Provider value={value}>
      {children}
      <CustomizableGrid
        localCardComponents={localCardComponents}
        renderOnCardNotFound={renderOnCardNotFound}
        renderCardWrapper={renderCardWrapper}
        gridSettings={gridSettings}
        onNewlyAddedCards={onNewlyAddedCards}
        // This allows us to bring up the width of the grid up to the Context
        // So other child components can be aware of the current grid width
        // eslint-disable-next-line max-len
        // See: https://github.com/ctrlplusb/react-sizeme#onsize-callback-alternative-usage
        onSize={onSize}
      />
    </CardGridContext.Provider>
  );
};

export default CardGrid;
