import React from 'react';
import styled from 'styled-components';
import { ActionButton } from '@adsk/alloy-react-menu';
import Tooltip from '@adsk/alloy-react-tooltip';
import theme from '@adsk/alloy-react-theme';
import { MoreVerticalIcon } from '@adsk/alloy-react-icon';
import Theme from '@adsk/alloy-react-theme';
import { useTranslations } from '../../hooks';
import HeaderButton from '../HeaderButton';
import { MenuItemsType, QuickItemsType } from './types';

export interface HeaderActionsProps {
  /** Class to be applied to the root element **/
  className?: string;
  /** Items to be displayed in menu **/
  menuItems?: MenuItemsType;
  /** Items/Buttons to be displayed as quick actions **/
  quickItems?: QuickItemsType;
}

const MoreIconContainer = styled.div({
  color: Theme.colors.charcoal700,
  display: 'flex',
});

const HeaderActions = (
  { className, quickItems, menuItems }: HeaderActionsProps
) => {
  const { T } = useTranslations();
  const renderMoreActionsIcon = () => (
    <Tooltip
      content={T.translate('COMPONENTS.CARD.MORE_ACTIONS')}
      data-testid='action-button-tooltip'
      placement='top'
    >
      <MoreIconContainer>
        <MoreVerticalIcon />
      </MoreIconContainer>
    </Tooltip>
  );

  return (
    <div
      className={className}
      css={{
        display: 'flex',
        paddingLeft: '10px',
      }}
      data-testid='header-actions'
    >
      <div
        css={{
          display: 'flex',
        }}
      >
        {quickItems && quickItems.map((item, key) => (
          <HeaderButton
            key={key}
            tooltipText={item.tooltipText}
            renderIcon={item.renderIcon}
            onClick={item.onClick}
          />
        ))}
      </div>
      {menuItems &&
        <ActionButton
          onTouchEnd={(e) => {
            e.preventDefault();
            e.target.dispatchEvent(new Event('click', { bubbles: true }));
          }}
          data-testid='action-button'
          horizontal={false}
          options={menuItems}
          placement={ActionButton.PLACEMENTS.BOTTOM_END}
          renderIcon={renderMoreActionsIcon}
          style={{
            '&:hover, &:active, &:focus': {
              backgroundColor: theme.colors.charcoal100,
              border: 'none',
              boxShadow: 'none',
            },
            border: 'none',
          }}
        />
      }
    </div>
  );
};

export default HeaderActions;
