import React from 'react';

import PropTypes from 'prop-types';

import { variantSwitch } from '@adsk/alloy-react-helpers';
import theme, {
  stylePropType,
  StylableComponent,
} from '@adsk/alloy-react-theme';

const VARIANTS = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
} as const;

const COLORS = {
  SUBTLE: theme.tokens.colors.border.subtler.value,
  LIGHT: theme.tokens.colors.border.subtle.value,
  DARK: theme.tokens.colors.border.value,
} as const;

type Variants = typeof VARIANTS;
type Variant = Variants[keyof Variants];

type DividerProps = StylableComponent<HTMLHRElement> & {
  variant?: Variant;
  color?: string;
  height?: string | number;
  width?: string | number;
};

/**
 * A Divider component. Used to divide context horizontally or vertically.
 */
const Divider = React.forwardRef<HTMLHRElement, DividerProps>(
  ({ className, style, variant, color, height, width, ...props }, ref) => (
    <hr
      {...props}
      ref={ref}
      className={className}
      css={[
        {
          display: 'block',
          backgroundColor: color,
          border: 0,
          margin: 0,
        },
        variantSwitch({
          statement: variant,
          variants: {
            [VARIANTS.HORIZONTAL]: {
              width: width ?? '100%',
              height: height ?? 1,
            },
            [VARIANTS.VERTICAL]: {
              height: height ?? '100%',
              width: width ?? 1,
            },
          },
        }),
        style,
      ]}
    />
  ),
);

Divider.displayName = 'Divider';

Divider.defaultProps = {
  variant: VARIANTS.HORIZONTAL,
  color: COLORS.LIGHT,
};

Divider.propTypes = {
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,
  /** The style variant - horizontal or vertical */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  /** The divider color, either a string or one of Divider.COLORS */
  color: PropTypes.string,
  /** Divider width. Default to 100% for horizontal dividers */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Divider height. Default to 100% for vertical dividers */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Object.assign(Divider, { VARIANTS, COLORS });
