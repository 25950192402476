import { useCallback } from 'react';
import { Layouts } from 'react-grid-layout';
import { useContextWithValidation } from '@adsk/alloy-react-context';
import { CardGridContext } from './CardGrid';
import { actions } from './cardGridSlice';
import { CardConfig, CardData, CardLayout, CardSetting } from './types';

export const useCardGrid = () => {
  const {
    state,
    dispatch,
    onSave: onSaveCallback = () => { },
    size,
  } = useContextWithValidation(CardGridContext, {
    hook: 'useCardGrid',
    provider: 'CardGridProvider',
  });

  const addCards = useCallback(
    (cards: CardConfig[]) => dispatch(actions.addCards(cards)),
    [dispatch]
  );
  const removeCard = useCallback(
    (i: string) => dispatch(actions.removeCard(i)),
    [dispatch]
  );
  const resetCurrentLayout = useCallback(
    () => dispatch(actions.resetCurrentLayout()),
    [dispatch]
  );
  // saveSetting is used for saving card data that will be saved with 
  // the layout
  const saveSetting = useCallback(
    (cardSetting: CardSetting) => dispatch(actions.saveSetting(cardSetting)),
    [dispatch]
  );
  const setAllLayouts = useCallback(
    (allLayouts: Layouts) => dispatch(actions.setAllLayouts(allLayouts)),
    [dispatch]
  );
  // setCardState is used for maintaining any type of local state per card
  const setCardState = useCallback(
    (cardState: CardData) => dispatch(actions.setCardState(cardState)),
    [dispatch]
  );
  const setInitialDesktopLayout = useCallback(
    (initialDesktopLayout: CardLayout[]) =>
      dispatch(actions.setInitialDesktopLayout(initialDesktopLayout)),
    [dispatch]
  );
  const toggleCardLibrary = useCallback(
    () => dispatch(actions.toggleCardLibrary()),
    [dispatch]
  );
  const toggleCustomizeMode = useCallback(
    () => dispatch(actions.toggleCustomizeMode()),
    [dispatch]
  );
  const toggleFullPageMode = useCallback(
    (fullPageCardId: string) =>
      dispatch(actions.toggleFullPageMode(fullPageCardId)),
    [dispatch]
  );
  const onSave = useCallback(() => {
    const layoutToSave: CardLayout[] = state.allLayouts.desktop.map(
      (card) => {
        const { i, x, y, w, h } = card;
        const [cardId, id] = i.split('_');
        const cardInfo = {
          cardId,
          h,
          i,
          id,
          settings: { ...state.cardSettings[i] },
          w,
          x,
          y,
        };

        return cardInfo;
      }
    );

    onSaveCallback(layoutToSave);
    setInitialDesktopLayout(layoutToSave);
  }, [
    onSaveCallback,
    setInitialDesktopLayout,
    state.allLayouts.desktop,
    state.cardSettings,
  ]);

  return {
    addCards,
    onSave,
    removeCard,
    resetCurrentLayout,
    saveSetting,
    setAllLayouts,
    setCardState,
    size,
    state,
    toggleCardLibrary,
    toggleCustomizeMode,
    toggleFullPageMode,
  }
};
