import React from 'react';

import PropTypes from 'prop-types';

import { useDeprecate, variantSwitch } from '@adsk/alloy-react-helpers';
import { RenderIconType } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import theme, {
  getInteractiveStyles,
  stylePropType,
  StyleProp,
} from '@adsk/alloy-react-theme';

import { buttonTypePropType, StylableButtonProps } from './consts';
import BasicButton from './templates/BasicButton';

const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
} as const;

type ButtonVariants = typeof BUTTON_VARIANTS;
type ButtonVariant = ButtonVariants[keyof ButtonVariants];

const BUTTON_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
} as const;

type ButtonSizes = typeof BUTTON_SIZES;
type ButtonSize = ButtonSizes[keyof ButtonSizes];

/**
 * A button component which wraps an HTML button and adds to it the basic "btn" bootstrap class.
 * All arbitrary DOM properties and attributes (including event handlers) are accepted and passed to HTML button element.
 */
const Button = React.forwardRef<
  HTMLButtonElement,
  StylableButtonProps & {
    variant?: ButtonVariant;
    size?: ButtonSize;
    renderIcon?: RenderIconType;
    renderRightIcon?: RenderIconType;
    disabled?: boolean;
    alert?: boolean;
    loading?: boolean;
    selected?: boolean;
    width?: string | number;
    target?: string; // Might have a better way to add those props based on `as` prop.
    href?: string;
    rel?: string;
  }
>(
  (
    {
      style,
      className,
      children,
      variant = BUTTON_VARIANTS.SECONDARY,
      size = BUTTON_SIZES.MEDIUM,
      renderIcon,
      renderRightIcon,
      disabled = false,
      alert = false,
      loading = false,
      selected = false,
      type,
      ...props
    },
    ref,
  ) => {
    useDeprecate(!!props.width, 'Button', 'width', 'style');
    const disabledOrLoading = disabled || loading;
    const marginStyle = children
      ? { marginRight: theme.spacing.XS }
      : undefined;
    const textOnly = children && !renderIcon;
    const iconOnly = renderIcon && !children;
    const iconAndText = children && renderIcon;
    const iconStyle = variantSwitch<StyleProp>({
      statement: variant,
      variants: {
        [BUTTON_VARIANTS.PRIMARY]: {
          ...(disabled && {
            color: theme.tokens.colors.icon.inactive.value,
          }),
        },

        [BUTTON_VARIANTS.SECONDARY]: {
          color: theme.tokens.colors.icon.secondary.value,
          ...(alert && {
            color: theme.tokens.colors.icon.error.bold.value,
          }),
          ...(disabled && {
            color: theme.tokens.colors.icon.inactive.value,
          }),
        },
      },
    });

    return (
      <BasicButton
        type={type}
        tabIndex={disabled ? -1 : undefined}
        {...props}
        className={className}
        ref={ref}
        disabled={disabledOrLoading}
        aria-disabled={disabledOrLoading}
        style={[
          getInteractiveStyles(disabledOrLoading),
          {
            fontWeight: 600,
          },
          variantSwitch<StyleProp>({
            statement: variant,
            variants: {
              [BUTTON_VARIANTS.PRIMARY]: {
                backgroundColor: theme.tokens.colors.background.brand.value,
                border: '1px solid transparent',
                color: theme.tokens.colors.text.inverse.value,

                '&:active': {
                  backgroundColor:
                    theme.tokens.colors.background.brand.pressed.value,
                  borderColor: 'transparent',

                  ...(loading && {
                    background:
                      theme.tokens.colors.background.brand.inactive.value,
                  }),
                  ...(disabled && {
                    background:
                      theme.tokens.colors.background.neutral.inactive.value,
                  }),
                },

                ...(loading && {
                  background:
                    theme.tokens.colors.background.brand.inactive.value,
                  cursor: 'auto',
                }),

                ...(alert && {
                  background: theme.tokens.colors.background.error.bold.value,
                  color: theme.tokens.colors.text.error.inverse.value,

                  '&:active': {
                    backgroundColor:
                      theme.tokens.colors.background.error.bold.value,
                  },

                  ...(loading && {
                    background:
                      theme.tokens.colors.background.error.inactive.value,
                  }),
                }),

                ...(disabled && {
                  background:
                    theme.tokens.colors.background.neutral.inactive.value,
                  color: theme.tokens.colors.text.inactive.value,
                }),
              },
              [BUTTON_VARIANTS.SECONDARY]: {
                border: `1px solid ${theme.tokens.colors.border.value}`,
                color: theme.tokens.colors.text.primary.value,

                '&:active': {
                  ...(!disabled && {
                    borderColor: theme.tokens.colors.border.pressed.value,
                  }),
                },

                ...(alert && {
                  color: theme.tokens.colors.text.error.value,
                }),

                ...(disabled && {
                  borderColor: theme.tokens.colors.border.inactive.value,
                  color: theme.tokens.colors.text.inactive.value,
                }),

                ...(loading && {
                  cursor: 'auto',
                  color: theme.tokens.colors.text.inactive.value,
                  borderColor: theme.tokens.colors.border.inactive.value,
                }),

                ...(selected && {
                  background:
                    theme.tokens.colors.background.neutral.selected.value,

                  '&:hover': {
                    boxShadow: 'none',
                  },
                  '&:focus': {
                    boxShadow: 'none',
                  },
                  '&:active': {
                    boxShadow: 'none',
                  },
                }),
              },
              [BUTTON_VARIANTS.TERTIARY]: {
                border: '1px solid transparent',
                color: theme.tokens.colors.text.link.value,

                '&:hover': {
                  ...(!disabled && {
                    boxShadow: 'none',
                    background:
                      theme.tokens.colors.background.neutral.hover.value,
                  }),
                },
                '&:focus': {
                  boxShadow: theme.halos.focus,
                  background: 'none',
                },
                '&:active': {
                  ...(!disabled && {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                    background:
                      theme.tokens.colors.background.neutral.pressed.value,
                  }),
                },

                ...(alert && {
                  color: theme.tokens.colors.text.error.value,
                }),

                ...(disabled && {
                  color: theme.tokens.colors.text.inactive.value,
                }),

                ...(loading && {
                  cursor: 'auto',
                  color: theme.tokens.colors.text.inactive.value,
                  background: 'transparent',
                }),

                ...(selected && {
                  background:
                    theme.tokens.colors.background.neutral.selected.value,
                  color: theme.tokens.colors.text.link.value,
                  '&:hover': {
                    boxShadow: 'none',
                    background:
                      theme.tokens.colors.background.neutral.hover.value,
                  },
                  '&:focus': {
                    boxShadow: 'none',
                    background:
                      theme.tokens.colors.background.neutral.selected.value,
                  },
                  '&:active': {
                    boxShadow: 'none',
                    background:
                      theme.tokens.colors.background.neutral.selected.value,
                  },
                }),
              },
            },
          }),
          variantSwitch({
            statement: size,
            variants: {
              [BUTTON_SIZES.SMALL]: {
                ...theme.typography.labelSmall,
                height: 24,
                ...(textOnly && {
                  padding: `0 7px`,
                }),
                ...(iconOnly && {
                  padding: `0 3px`,
                }),
                ...(iconAndText && {
                  padding: `0 7px 0 5px`,
                }),
              },
              [BUTTON_SIZES.MEDIUM]: {
                ...theme.typography.labelMedium,
                height: 36,
                ...(textOnly && {
                  padding: `0 15px`,
                }),
                ...(iconOnly && {
                  padding: `0 5px`,
                }),
                ...(iconAndText && {
                  padding: `0 15px 0 11px`,
                }),
              },
            },
          }),
          style,
        ]}
      >
        {loading && renderIcon && (
          <ProgressRing
            style={marginStyle}
            variant={
              variant === BUTTON_VARIANTS.PRIMARY
                ? ProgressRing.VARIANTS.ALTERNATIVE
                : ProgressRing.VARIANTS.DEFAULT
            }
            size={
              size === BUTTON_SIZES.SMALL
                ? ProgressRing.SIZES.XSMALL
                : ProgressRing.SIZES.SMALL
            }
          />
        )}
        {loading && !renderIcon && (
          <ProgressRing
            style={{
              position: 'absolute',
            }}
            variant={
              variant === BUTTON_VARIANTS.PRIMARY
                ? ProgressRing.VARIANTS.ALTERNATIVE
                : ProgressRing.VARIANTS.DEFAULT
            }
            size={
              size === BUTTON_SIZES.SMALL
                ? ProgressRing.SIZES.XSMALL
                : ProgressRing.SIZES.SMALL
            }
          />
        )}
        {!loading &&
          renderIcon?.({
            size: size === BUTTON_SIZES.SMALL ? 16 : 24,
            style: [marginStyle, iconStyle],
          })}
        {children && (
          <label
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              pointerEvents: 'none',
              opacity: loading && !renderIcon ? 0 : 1,
            }}
          >
            {children}
          </label>
        )}
        {renderRightIcon?.({
          size: size === BUTTON_SIZES.SMALL ? 16 : 24,
          style: children ? { marginLeft: theme.spacing.XS } : undefined,
        })}
      </BasicButton>
    );
  },
);

Button.displayName = 'Button';

Button.propTypes = {
  /** Button's content */
  children: PropTypes.any,
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,
  /** button html type, eg: submit */
  type: buttonTypePropType,
  /** Disable button functionality */
  disabled: PropTypes.bool,
  /** handle onclick event for button */
  onClick: PropTypes.func,
  /** render prop to display an icon on the left */
  renderIcon: PropTypes.func,
  /** render prop to display an icon on the right */
  renderRightIcon: PropTypes.func,
  /**  The size of the button, defaults to 'medium' */
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  /** Deprecated: Adjust width using style instead */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**  The style variant, defaults to 'secondary' */
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
  /** alert mode */
  alert: PropTypes.bool,
  /** is loading state is on */
  loading: PropTypes.bool,
  /** is selected state is on */
  selected: PropTypes.bool,
};

export default Object.assign(Button, {
  VARIANTS: BUTTON_VARIANTS,
  SIZES: BUTTON_SIZES,
});
