import React from 'react';
import { CollapseIcon, ExpandIcon } from '@adsk/alloy-react-icon';
import { MenuItemsType, QuickItemsType } from '../HeaderActions/types';
import { DefaultHeaderItemsProps } from './types';

export interface DefaultHeaderItemsType {
  menuItems: MenuItemsType;
  quickItems: QuickItemsType;
}

export const getIcon = (isFullPage?: boolean) => {
  const icon = isFullPage ? <CollapseIcon /> : <ExpandIcon />;

  return () => icon;
};

export const getDefaultHeaderItems = ({
  disableRemoveAction,
  isFullPage,
  isCustomizeMode,
  onHide,
  onToggleExpand,
  T,
}: DefaultHeaderItemsProps): DefaultHeaderItemsType => ({
  menuItems: isCustomizeMode ? [{
    disabled: disableRemoveAction,
    key: 'remove',
    label: T?.translate('COMPONENTS.CARD.REMOVE'),
    onClick: onHide,
  }] : [],
  quickItems: !isCustomizeMode ? [{
    onClick: onToggleExpand,
    renderIcon: getIcon(isFullPage),
    tooltipText: (
      isFullPage ?
        T?.translate('COMPONENTS.CARD.COLLAPSE') :
        T?.translate('COMPONENTS.CARD.EXPAND')
    ) as string,
  }] : [],
});
