import React from 'react';
import styled from 'styled-components';
import Tooltip from '@adsk/alloy-react-tooltip';
import { IconButton } from '@adsk/alloy-react-button'
import { RenderIconType } from '@adsk/alloy-react-icon';
import theme from '@adsk/alloy-react-theme';

export interface HeaderButtonProps {
  /** tooltipText **/
  tooltipText?: string;
  /** Function to render the icon **/
  renderIcon: RenderIconType;
  /** Button icon color**/
  iconColor?: string;
  /** Callback when HeaderButton is clicked **/
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

type ButtonWrapperProps = Pick<HeaderButtonProps, 'iconColor'>;

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  > button {
    border-radius: 2px;
    padding: 6px;
    color: ${props => props.iconColor || theme.colors.charcoal900};
    &:hover, &:active, &:focus {
      background-color: ${theme.colors.charcoal050};
      color: ${props => props.iconColor || theme.colors.charcoal900};
    }
  }
`
const HeaderButton = (
  {
    tooltipText,
    renderIcon,
    iconColor,
    onClick,
  }: HeaderButtonProps
) => {
  return (
    <Tooltip 
      content={tooltipText}
      placement="top"
      data-testid='header-button-tooltip'
    >
      <ButtonWrapper 
        data-testid='header-button-wrapper'
        iconColor={iconColor}
      >
        <IconButton 
          data-testid='header-button-icon'
          size={36}
          onClick={onClick}
          renderIcon={renderIcon}
        />
      </ButtonWrapper>
    </Tooltip>
  );
}

export default HeaderButton
