import colors from '../styles/colors';

interface TokenValue {
  value?: string;
}

type TokenHolder = {
  [key: string]: Token;
};

type Token = TokenValue | TokenHolder;

/**
 * This type is the same as what style-dictionary expects for tokens
 * If we start using style-dictionary in the future, use its type instead
 */
type ColorTokens = {
  [key: string]: Token;
};

const backgroundAccentTokens = {
  one: {
    value: colors.adskBlue500 /* background-accent-one */,
    bold: {
      value: colors.adskBlue700 /* background-accent-one-bold */,
    },
    subtle: {
      value: colors.darkBlue300 /* background-accent-one-subtle */,
    },
    subtler: {
      value: colors.adskBlue100 /* background-accent-one-subtler */,
    },
  },
  two: {
    bold: {
      value: colors.turquoise700 /* background-accent-two-bold */,
    },
    value: colors.green300 /* background-accent-two */,
    subtle: {
      value: colors.turquoise300 /* background-accent-two-subtle */,
    },
  },
  three: {
    value: colors.purple500 /* background-accent-three */,
    bold: {
      value: colors.purple700 /* background-accent-three-bold */,
    },
    subtle: {
      value: colors.purple300 /* background-accent-three-subtle */,
    },
  },
  four: {
    value: colors.pink500 /* background-accent-four */,
    subtle: {
      value: colors.pink300 /* background-accent-four-subtle */,
    },
  },
  five: {
    value: colors.yellowOrange500 /* background-accent-five */,
    bold: {
      value: colors.yellowOrange700 /* background-accent-five-bold */,
    },
    subtle: {
      value: colors.salmon300 /* background-accent-five-subtle */,
    },
    subtler: {
      value: colors.yellowOrange300 /* background-accent-five-subtler */,
    },
  },
  six: {
    value: colors.charcoal500 /* background-accent-six */,
    bold: {
      value: colors.charcoal700 /* background-accent-six-bold */,
    },
    bolder: {
      value: colors.charcoal900 /* background-accent-six-bolder */,
    },
    subtle: {
      value: colors.charcoal200 /* background-accent-six-subtle */,
    },
    subtler: {
      value: colors.charcoal100 /* background-accent-six-subtler */,
    },
  },
} as const;

const colorTokens = {
  background: {
    accent: backgroundAccentTokens,
    active: {
      value: colors.adskBlue500 /* background-active */,
    },
    brand: {
      value: colors.adskBlue500 /* background-brand */,
      hover: {
        value: colors.adskBlue600 /* background-brand-hover */,
      },
      inactive: {
        value: colors.adskBlue300 /* background-brand-inactive */,
      },
      pressed: {
        value: colors.adskBlue700 /* background-brand-pressed */,
      },
    },
    closed: {
      value: backgroundAccentTokens.six.subtle.value /* background-closed */,
    },
    draft: {
      value: backgroundAccentTokens.six.bolder.value /* background-draft */,
    },
    error: {
      value: colors.red500 /* background-error */,
      bold: {
        value: colors.red700 /* background-error-bold */,
      },
      inactive: {
        value: colors.red300 /* background-error-inactive */,
      },
      subtle: {
        value: colors.red300Alpha35 /* background-error-subtle */,
      },
      subtler: {
        value: colors.red100 /* background-error-subtler */,
      },
    },
    focused: {
      value: colors.adskBlue100 /* background-focus */,
    },
    hover: {
      value: colors.adskBlue300 /* background-hover */,
    },
    inactive: {
      value: colors.adskBlue100 /* background-inactive */,
    },
    inverse: {
      value: colors.black /* background-inverse */,
      hover: {
        value: colors.charcoal700 /* background-inverse-hover */,
      },
      pressed: {
        value: colors.charcoal500 /* background-inverse-pressed */,
      },
      selected: {
        value: colors.charcoal200 /* background-inverse-selected */,
      },
      subtle: {
        value: colors.charcoal900 /* background-inverse-subtle */,
      },
      subtler: {
        value: colors.charcoal600 /* background-inverse-subtler */,
      },
    },
    information: {
      value: colors.adskBlue500 /* background-information */,
      subtle: {
        value: colors.adskBlue300Alpha35 /* background-information-subtle */,
      },
      subtler: {
        value: colors.darkBlue100 /* background-information-subtler */,
      },
    },
    neutral: {
      hover: {
        value: colors.charcoal050 /* background-neutral-hover */,
        bold: {
          value: colors.charcoal200 /* background-neutral-hover-bold */,
        },
      },
      inactive: {
        value: colors.charcoal100 /* background-neutral-inactive */,
      },
      pressed: {
        value: colors.charcoal300 /* background-neutral-pressed */,
      },
      selected: {
        value: colors.charcoal100 /* background-neutral-selected */,
      },
    },
    open: {
      value: backgroundAccentTokens.five.value /* background-open */,
    },
    pending: {
      value: backgroundAccentTokens.one.value /* background-pending */,
    },
    primary: {
      value: colors.white /* background-primary */,
    },
    removed: {
      value: backgroundAccentTokens.six.value /* background-removed */,
    },
    review: {
      value: backgroundAccentTokens.three.value /* background-review */,
    },
    secondary: {
      value: colors.charcoal050 /* background-secondary */,
    },
    selected: {
      value: colors.adskBlue500 /* background-selected */,
      inactive: {
        value: colors.adskBlue300 /* background-selected-inactive */,
      },
      subtle: {
        value: colors.adskBlue300 /* background-selected-subtle */,
      },
      subtler: {
        value: colors._adskBlue100Alpha /* background-selected-subtler */,
      },
    },
    success: {
      value: colors.green500 /* background-success */,
      subtle: {
        value: colors.green300Alpha35 /* background-success-subtle */,
      },
      subtler: {
        value: colors.green100 /* background-success-subtler */,
      },
    },
    tertiary: {
      value: colors.charcoal100 /* background-tertiary */,
    },
    warning: {
      value: colors.yellowOrange500 /* background-warning */,
      subtle: {
        value: colors.yellowOrange300Alpha35 /* background-warning-subtle */,
      },
      subtler: {
        value: colors.yellowOrange100 /* background-warning-subtler */,
      },
    },
  },
  border: {
    value: colors.charcoal400 /* border */,
    action: {
      /**
       * @deprecated use use border.value
       */
      value: colors.charcoal400 /* border-action */,
      pressed: {
        /**
         * @deprecated use use border.pressed
         */
        value: colors.charcoal500 /* border-action-pressed */,
      },
    },
    active: {
      value: colors.adskBlue500 /* border-active */,
    },
    error: {
      value: colors.red500 /* border-error */,
    },
    focused: {
      value: colors.adskBlue500 /* border-focused */,
    },
    hover: {
      value: colors.charcoal400 /* border-hover */,
      dropzone: {
        value: colors.adskBlue500 /* border-hover-dropzone */,
      },
    },
    inactive: {
      value: colors.charcoal200 /* border-inactive */,
    },
    information: {
      value: colors.adskBlue500 /* border-information */,
    },
    inverse: {
      value: colors.white /* border-inverse */,
    },
    neutral: {
      focused: {
        value: colors.charcoal400 /* border-neutral-focused */,
      },
    },
    pressed: {
      value: colors.charcoal500 /* border-pressed */,
    },
    selected: {
      value: colors.adskBlue500 /* border-selected */,
      subtle: {
        value: colors.adskBlue300 /* border-selected-subtle */,
      },
    },
    subtle: {
      value: colors.charcoal200 /* border-subtle */,
    },
    subtler: {
      value: colors.charcoal100 /* border-subtler */,
    },
    success: {
      value: colors.green500 /* border-success */,
    },
    warning: {
      value: colors.yellowOrange500 /* border-warning */,
    },
  },
  icon: {
    brand: {
      inverse: {
        /**
         * @deprecated use icon.inverse
         */
        value: colors.white /* icon-brand-inverse */,
      },
    },
    error: {
      value: colors.red500 /* icon-error */,
      bold: {
        value: colors.red700 /* icon-error-bold */,
      },
    },
    hover: {
      value: colors.adskBlue500 /* icon-hover */,
    },
    inactive: {
      value: colors.charcoal500 /* icon-inactive */,
    },
    information: {
      value: colors.adskBlue500 /* icon-information */,
    },
    inverse: {
      value: colors.white /* icon-inverse */,
    },
    link: {
      value: colors.adskBlue700 /* icon-link */,
    },
    load: {
      value: colors.adskBlue500 /* icon-load */,
    },
    pressed: {
      value: colors.adskBlue700 /* icon-pressed */,
    },
    primary: {
      value: colors.charcoal900 /* icon-primary */,
    },
    secondary: {
      value: colors.charcoal700 /* icon-secondary */,
    },
    selected: {
      value: colors.adskBlue500 /* icon-selected */,
    },
    success: {
      value: colors.green500 /* icon-success */,
    },
    tertiary: {
      value: colors.charcoal600 /* icon-tertiary */,
    },
    warning: {
      value: colors.yellowOrange500 /* icon-warning */,
    },
  },
  skeleton: {
    value: colors.charcoal200 /* skeleton */,
    shimmer: {
      one: {
        value: colors.charcoal100 /* skeleton-shimmer-one */,
      },
      two: {
        value: colors.charcoal200 /* skeleton-shimmer-two */,
      },
      three: {
        value: colors.charcoal300 /* skeleton-shimmer-three */,
      },
      four: {
        value: colors.charcoal400 /* skeleton-shimmer-four */,
      },
      five: {
        value: colors.charcoal500 /* skeleton-shimmer-five */,
      },
    },
  },
  text: {
    brand: {
      inverse: {
        /**
         * @deprecated use text.inverse
         */
        value: colors.white /* text-brand-inverse */,
      },
    },
    error: {
      value: colors.red700 /* text-error */,
      inverse: {
        value: colors.white /* text-error-inverse */,
      },
    },
    hover: {
      value: colors.adskBlue500 /* text-hover */,
    },
    inactive: {
      value: colors.charcoal500 /* text-inactive */,
    },
    inverse: {
      value: colors.white /* text-brand-inverse */,
    },
    link: {
      value: colors.adskBlue700 /* text-link */,
    },
    pressed: {
      value: colors.adskBlue700 /* text-pressed */,
    },
    primary: {
      value: colors.charcoal900 /* text-primary */,
    },
    secondary: {
      value: colors.charcoal700 /* text-secondary */,
    },
    selected: {
      value: colors.adskBlue500 /* text-selected */,
    },
    tertiary: {
      value: colors.charcoal600 /* text-tertiary */,
    },
  },
} as const;

type ConcreteType = typeof colorTokens;

const typeVerified: ColorTokens = colorTokens;

export default typeVerified as ConcreteType;
