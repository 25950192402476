export const CARD_GRID_CONTEXT = 'CARD_GRID_CONTEXT';
export const DEFAULT_GRID_SETTINGS = {
  breakpoints: {
    desktop: 1023,
    mobile: 320,
    tablet: 767,
  },
  cols: {
    desktop: 12,
    mobile: 3,
    tablet: 6,
  },
  draggableCancel: '',
  draggableHandle: '',
  isResizable: false,
  margin: [15, 15] as [number, number],
  rowHeight: 135,
  useCSSTransforms: false,
};
