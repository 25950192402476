import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ReactNode,
} from 'react';

import PropTypes from 'prop-types';
import styledC from 'styled-components';

import { noop } from '@adsk/alloy-react-helpers';
import {
  CheckmarkIcon,
  ChevronRightIcon,
  Icon,
  RenderIconType,
} from '@adsk/alloy-react-icon';
import theme, {
  StylableComponent,
  stylePropType,
} from '@adsk/alloy-react-theme';

type MenuItemProps = StylableComponent<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
> &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'style' | 'css' | 'onClick'> & {
    'data-testid'?: string;
    renderIcon?: RenderIconType;
    label?: ReactNode;
    rightSideLabel?: ReactNode;
    selected?: boolean;
    showSelection?: boolean;
    highlighted?: boolean;
    nested?: boolean;
  };

type StyledButtonProps = {
  disabled?: boolean;
  $hasChildren: boolean;
  $showSelection?: boolean;
  $selected?: boolean;
  $highlighted?: boolean;
};

const highlightedStyle = `
color: ${theme.tokens.colors.text.inverse.value};
background: ${theme.tokens.colors.background.selected.value};
`;

const StyledButton = styledC.button<StyledButtonProps>`
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  border-radius: 2px;
  outline: none;
  text-decoration: none;
  position: relative;
  justify-content: start;
  width: 100%;
  height: 36px;
  margin: 0px;
  &:focus-within {
    box-shadow: none;
  }
  &:hover {
    background: ${(props) =>
      props.$highlighted
        ? theme.tokens.colors.background.selected.value
        : theme.tokens.colors.background.neutral.hover.value};
  }
  &:active {
    background: ${(props) =>
      props.$highlighted
        ? theme.tokens.colors.background.selected.value
        : theme.tokens.colors.background.neutral.pressed.value};
  }
  padding: ${(props) =>
    props.$hasChildren
      ? `0 40px 0 ${theme.spacing.M}px`
      : `0 ${theme.spacing.M}px`};
  cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
  color: ${(props) =>
    props.disabled
      ? theme.tokens.colors.text.inactive.value
      : theme.tokens.colors.text.primary.value};
  ${(props) =>
    (props.$showSelection && props.$selected) || props.$highlighted
      ? theme.typography.bodyMediumBold
      : theme.typography.bodyMedium};
  ${(props) => (props.$highlighted ? highlightedStyle : undefined)}
`;

/** A MenuItem component which wraps an HTML li containing other children */
const MenuItem = React.forwardRef<HTMLButtonElement, MenuItemProps>(
  (
    {
      children,
      label,
      rightSideLabel,
      selected = false,
      disabled = false,
      renderIcon,
      className,
      showSelection = true,
      nested = false,
      highlighted,
      onClick,
      style,
      ...props
    },
    ref,
  ) => (
    <StyledButton
      ref={ref}
      as={nested ? 'div' : undefined}
      className={className}
      disabled={disabled}
      onClick={disabled ? noop : onClick}
      $showSelection={showSelection}
      $selected={selected}
      $hasChildren={!!children || !!rightSideLabel}
      $highlighted={highlighted}
      css={style}
      {...props}
    >
      {showSelection &&
        (selected ? (
          <CheckmarkIcon
            style={{
              flex: '0 0 22',
              color: `${theme.tokens.colors.icon.selected.value}`,
              marginRight: theme.spacing.XS,
              flexShrink: 0,
            }}
          />
        ) : (
          /* placeholder for styling, no icon displayed */
          <Icon
            style={{
              flex: '0 0 22',
              marginRight: theme.spacing.XS,
              flexShrink: 0,
            }}
          />
        ))}
      {renderIcon?.({
        style: {
          marginRight: theme.spacing.XS,
          flexShrink: 0,
        },
      })}
      {label && (
        <span
          css={[
            theme.typography.ellipsis,
            {
              whiteSpace: 'nowrap',
            },
          ]}
        >
          {label}
        </span>
      )}

      {children}

      {rightSideLabel && (
        <span
          css={[
            theme.typography.bodySmall,
            {
              position: 'absolute',
              right: 10,
              color: theme.tokens.colors.text.secondary.value,
            },
          ]}
        >
          {rightSideLabel}
        </span>
      )}

      {nested && (
        <ChevronRightIcon
          style={{
            position: 'absolute',
            right: 10,
            color: `${theme.tokens.colors.icon.secondary.value}`,
          }}
        />
      )}
    </StyledButton>
  ),
);

MenuItem.displayName = 'MenuItem';

MenuItem.propTypes = {
  /** The content to category */
  children: PropTypes.any,
  /* Styles applied to the root element */
  style: stylePropType,
  /* Class applied to the root element */
  className: PropTypes.string,
  /** Determines if the item is selected and show V next to it */
  selected: PropTypes.bool,
  /** Determines if the V icon will show */
  showSelection: PropTypes.bool,
  /** Determines if item should apply highlighted styles  */
  highlighted: PropTypes.bool,
  /** Determines if the > icon will show */
  nested: PropTypes.bool,
  /** Determines if the item is disabled */
  disabled: PropTypes.bool,
  /** The button text */
  label: PropTypes.any,
  /** A text on the right side */
  rightSideLabel: PropTypes.any,
  /* Icon type to display on left */
  renderIcon: PropTypes.func,
  /** Handle for item click event */
  onClick: PropTypes.func,
};
export default MenuItem;
