import React from 'react';

import { createDomMotionComponent, HTMLMotionProps } from 'framer-motion';
import PropTypes from 'prop-types';

import { stylePropType, StylableComponent } from '@adsk/alloy-react-theme';

// Fixes motion in IE: https://github.com/framer/motion/pull/781
const motion = {
  div: createDomMotionComponent('div'),
};

const FramerMotion = motion.div;

type AnimationProps = Omit<
  StylableComponent<HTMLDivElement>,
  'onAnimationStart'
> &
  Omit<HTMLMotionProps<'div'>, 'css' | 'style'>;

/**
 * A Animation Provides wrapper for framer-motion {motion.div}
 * API: https://www.framer.com/api/motion/animation/
 */
const Animation = React.forwardRef<HTMLDivElement, AnimationProps>(
  ({ children, className, style, inherit, ...props }, ref) => (
    <FramerMotion
      ref={ref}
      className={className}
      css={[style]}
      inherit={inherit}
      {...props}
    >
      {children}
    </FramerMotion>
  ),
);

Animation.displayName = 'Animation';

Animation.defaultProps = {
  inherit: false,
};

Animation.propTypes = {
  /** Animation content */
  children: PropTypes.any,
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,

  // (JSX attribute) initial?: string | boolean | MakeCustomValueType<TargetProperties> | string[]
  // Properties, variant label or array of variant labels to start in.
  // Set to false to initialise with the values in animate (disabling the mount animation)
  // <Animation initial={{ opacity: 1 }} />
  // <Animation initial="visible" variants={variants} />
  // <Animation initial={["visible", "active"]} variants={variants} />
  // <Animation initial={false} animate={{ opacity: 0 }} />
  initial: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),

  // Values to animate to, variant label(s), or AnimationControls.
  // <Animation animate={{ opacity: 1 }} />
  // <Animation animate="visible" variants={variants} />
  // <Animation animate={["visible", "active"]} variants={variants} />
  // <Animation animate={animation} />
  animate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),

  // Set to false to prevent inheriting variant changes from its parent.
  inherit: PropTypes.bool,

  // Default transition. If no transition is defined in animate, it will use the transition defined here.
  // <Animation
  //   transition={{
  //     type: 'spring',
  //     damping: 10,
  //     stiffness: 100,
  //   }}
  //   animate={{ scale: 1.2 }}
  // />
  transition: PropTypes.object,
};

export default Animation;
