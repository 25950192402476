// called from index.ts

import { ColorMode } from './color-modes';
import { DensityMode } from './density-modes';
import { JsonType } from './json-types';
import { Theme, ThemeNoPrefix } from './themes';

import designTokens from '../dist/json/web-nested.json';

export type DesignTokens = typeof designTokens;

type Mode = ColorMode | DensityMode;

export interface DesignTokenOptionsOptions {
  /**
   * responsive:
   *
   * true: use tokens with responsive units rem/em, false: use pixel based units
   * @default false
   */
  responsive?: boolean;
  origin?: string; // e.g. https://www.example.com:3000  -should NOT end with a forward slash
  path?: string; // e.g. /folder1/folder2/mydata.json  -should start with forward slash
}

export interface DesignTokenOptions {
  jsonType?: JsonType;
  theme?: string;
  modes?: Mode[];
  options?: DesignTokenOptionsOptions;
}

// Theme provider is now agnostic to the existing themes and receive any string
// This function replace the theme received from theme provider and convert it to the correct structure
// light-gray -> hig-light-gray
// brand-dark -> digital-hig-brand-dark
// This should be refactor in https://jira.autodesk.com/browse/FUS-154024 and Design tokens will provide a function with the available themes (each with the official name of the theme and the "friendly" name).
const chooseThemePath = (theme: string) => {
  let prefix =
    theme === ThemeNoPrefix.BrandLight || theme === ThemeNoPrefix.BrandDark ? 'digital-hig' : 'hig';
  let themeName = `${prefix}-${theme}`;
  return themeName;
};

export const loadDesignTokenMap = async ({
  theme = ThemeNoPrefix.Default,
  modes = [],
}: DesignTokenOptions): Promise<DesignTokens> => {
  if (!themeAvailable(theme) || theme === '') {
    theme = ThemeNoPrefix.Default;
  }
  // Replace the labeled default theme with an empty string, since the default
  // theme is represented as the core theme - ie:
  //
  // Instead of:
  // - web-nested.hig-light-gray.json
  // Use:
  // - web-nested.json

  let themePath: string | undefined = theme
    ? theme === ThemeNoPrefix.Default
      ? ''
      : chooseThemePath(theme)
    : undefined;

  let modePath: string | undefined = modes
    ? modes
        .map((mode) => (mode === ColorMode.Default ? '' : mode === DensityMode.Default ? '' : mode))
        .filter((value) => !!value)
        .join('.')
    : undefined;

  return import(
    `../dist/json-components/web-components${themePath ? `.${themePath}` : ''}${
      modePath ? `.${modePath}` : ''
    }.json`
  )
    .then((module) => module.default)
    .catch((error) => console.error(error));
};

const queueMap = new Map();
const tokenMap = new Map();

/**
 * loadDesignTokens
 *
 * returns tokens in object
 *
 * @param jsonType? {JsonType}
 * @param theme? {ThemeNoPrefix}
 * @param modes?
 * @param options? {DesignTokenOptionsOptions}
 */
export const loadDesignTokens = ({
  jsonType = JsonType.Default,
  theme = ThemeNoPrefix.Default,
  modes = [],
  options = { responsive: false, origin: undefined, path: undefined },
}: DesignTokenOptions): Promise<DesignTokens> => {
  return new Promise((resolve, reject) => {
    // handle options passed in that may be invalid

    if (options.responsive === undefined) {
      options.responsive = false;
    }

    if (!themeAvailable(theme) || theme === '') {
      theme = ThemeNoPrefix.Default;
    }

    // Replace the labeled default theme with an empty string, since the default
    // theme is represented as the core theme - ie:
    //
    // Instead of:
    // - web-nested.hig-light-gray.json
    // Use:
    // - web-nested.json

    const themePath: string | undefined = theme
      ? theme === ThemeNoPrefix.Default
        ? ''
        : chooseThemePath(theme)
      : undefined;

    const directory: string = options.responsive ? 'json-responsive' : 'json';

    let modePath: string | undefined = modes
      ? modes
          .map((mode) =>
            mode === ColorMode.Default ? '' : mode === DensityMode.Default ? '' : mode,
          )
          .filter((value) => !!value)
          .join('.')
      : undefined;

    let bHaveUserSuppliedTokenUriOrigin: boolean;
    let bHaveUserSuppliedTokenUriPath: boolean;
    let bGetTokensViaInportOrGet: boolean;

    if (options.origin && options.origin.length > 0) {
      // present

      if (options.origin.endsWith('/')) {
        // invalid
        // make valid -drop ending '/'

        options.origin = options.origin.substring(0, options.origin.length - 1);
      } else {
        // valid
      }

      bHaveUserSuppliedTokenUriOrigin = true;
    } else {
      // invalid origin

      options.origin = undefined;

      bHaveUserSuppliedTokenUriOrigin = false;
    }

    if (options.path && options.path.length > 0) {
      // present

      if (options.path.startsWith('/')) {
        // valid
      } else {
        // invalid
        // make valid by add leading '/'

        options.origin = '/' + options.origin;
      }

      bHaveUserSuppliedTokenUriPath = true;
    } else {
      // invalid path

      options.path = undefined;

      bHaveUserSuppliedTokenUriPath = false;
    }

    // here with all options, if present are all valid format

    // build uri

    let importPath: string;

    const defaultOrigin = '..';
    const defaultPath = `/dist/${directory}/web-${jsonType}${themePath ? `.${themePath}` : ''}${
      modePath ? `.${modePath}` : ''
    }.json`;

    const defaultUri = `${defaultOrigin}${defaultPath}`;

    if (bHaveUserSuppliedTokenUriOrigin) {
      // present

      if (bHaveUserSuppliedTokenUriPath) {
        // present
        // have origin+path

        bGetTokensViaInportOrGet = false;
        importPath = `${options.origin}${options.path}`;
      } else {
        // no path.
        // have origin only

        bGetTokensViaInportOrGet = false;

        importPath = `${options.origin}${defaultPath}`;
      }
    } else {
      // no origin

      if (bHaveUserSuppliedTokenUriPath) {
        // present
        // have path only

        bGetTokensViaInportOrGet = false;

        importPath = `${defaultOrigin}${options.path}`;
      } else {
        // no path
        // no origin nor path

        bGetTokensViaInportOrGet = true;

        importPath = `${defaultOrigin}${defaultPath}`;
      }
    }

    console.log(`effective uri is "${importPath}"`);

    const tokens = tokenMap.get(importPath);
    if (tokens) {
      resolve(tokens);
      return;
    }

    let queue: any;

    if (bGetTokensViaInportOrGet) {
      // import()

      queue = queueMap.get(importPath);
      if (queue) {
        queue.push(resolve);
        return;
      } else {
        queueMap.set(importPath, [resolve]);
      }
    } else {
      // get
      // nop
    }

    let myPromise: Promise<any>;

    if (bGetTokensViaInportOrGet) {
      // import

      console.log(`getting tokens from "${importPath}" via dynamic import`);

      // import() can not take a variable :(
      myPromise = import(
        `../dist/${directory}/web-${jsonType}${themePath ? `.${themePath}` : ''}${
          modePath ? `.${modePath}` : ''
        }.json`
      );
    } else {
      // get
      console.log(`getting tokens from "${importPath}" via fetch`);

      myPromise = fetch(importPath);
    }

    myPromise
      .then((module: any) => {
        if (bGetTokensViaInportOrGet) {
          // import

          let queue: any;

          // import()

          tokenMap.set(importPath, module.default);

          queue = queueMap.get(importPath);

          queue.forEach((element: any) => element(module.default));
        } else {
          // custom

          module.json().then((module: any) => {
            return resolve(module);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// This is meant to be used with layered JSON from the Design Token package;
// however, the Design Token package does not currently output Layered JSON;
// but, does output complete baked token sets.
// Until the Design Token package outputs layered JSON, it's recommended to use
// the loadDesignTokens method instead.
export const layer = ({
  jsonType = JsonType.Default,
  theme,
  modes = [],
}: {
  jsonType?: JsonType;
  theme: Theme;
  modes?: Mode[];
}) =>
  Promise.all([
    loadDesignTokens({ jsonType }),
    loadDesignTokens({ jsonType, theme }),
    ...modes.flatMap((mode) => [
      loadDesignTokens({ jsonType, theme: ThemeNoPrefix.Default, modes: [mode] }),
      loadDesignTokens({ jsonType, theme, modes: [mode] }),
    ]),
  ])
    .then((value) => Object.assign({}, ...value))
    .catch((error) => {
      console.error(error);
    });

// this method is meant to be used to check if the received string correspond to one of the available themes.
export const themeAvailable = (theme: string) =>
  Object.values(ThemeNoPrefix).toLocaleString().includes(theme);
