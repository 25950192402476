import React, {
  useMemo,
  useEffect,
  ComponentProps,
  ReactElement,
  Ref,
} from 'react';

import PropTypes from 'prop-types';

import Divider from '@adsk/alloy-react-divider';
import {
  optionalPropType,
  useControlled,
  warn,
} from '@adsk/alloy-react-helpers';
import Overlay from '@adsk/alloy-react-overlay';
import { stylePropType } from '@adsk/alloy-react-theme';

import { getOptionsDuplicatedKeys } from './helpers/helpers';
import { menuItemShape, menuCategoryShape } from './helpers/propTypes';
import { MenuCategoryType, MenuItemType, SharedProps } from './helpers/types';
import MenuCategory from './layout/MenuCategory';
import MenuList from './layout/MenuList';
import OverlayMenuItem from './layout/OverlayMenuItem';

export type MenuProps<TKey extends string | number> = Omit<
  ComponentProps<typeof Overlay.Container>,
  'defaultValue' | 'onChange' | 'ref'
> &
  Pick<
    ComponentProps<typeof OverlayMenuItem>,
    'onClose' | 'onClick' | 'renderItem'
  > &
  SharedProps<TKey> & {
    'data-testid'?: string;
    options: (MenuItemType<TKey> | MenuCategoryType<TKey>)[];
    width?: number | string;
  };
/** Menu is intended to be a component that build a menu out of other components */
const MenuWrapped = <TKey extends string | number>(
  {
    style,
    className,
    options,
    defaultValue = [],
    value,
    width = 300,
    onChange,
    onClose,
    renderItem,
    ...props
  }: MenuProps<TKey>,
  ref: Ref<HTMLDivElement>,
) => {
  const [selectedItems, setSelectedItems] = useControlled<TKey[]>(
    defaultValue,
    value,
    onChange,
  );

  const optionsWithCategory = useMemo<MenuCategoryType<TKey>[]>(() => {
    if (!options) return [];
    if (options && options[0]?.hasOwnProperty('itemOptions')) {
      return options as MenuCategoryType<TKey>[];
    }
    return [
      {
        key: (typeof options[0].key === 'string' ? 'single-menu' : 0) as TKey,
        allowReselect: true,
        showSelection: false,
        multiSelection: false,
        itemOptions: options as MenuItemType<TKey>[],
      },
    ];
  }, [options]);

  useEffect(() => {
    const duplicates = getOptionsDuplicatedKeys(optionsWithCategory);
    if (duplicates?.length > 0) {
      warn(
        `[Menu]: Options has Duplicate keys: ${duplicates.join(
          ', ',
        )}, please use unique menu item key among all catagories`,
      );
    }
  }, [optionsWithCategory]);

  return (
    <Overlay.Container
      ref={ref}
      className={className}
      style={[{ width }, style]}
      variant={Overlay.VARIANTS.LIGHT}
      {...props}
    >
      {optionsWithCategory.map((category, index) => (
        <MenuCategory key={category.key} title={category.title}>
          <MenuList>
            {category.itemOptions.map(({ key, ...rest }) => (
              <OverlayMenuItem
                key={key}
                itemKey={key}
                renderItem={renderItem}
                category={category}
                defaultValue={defaultValue}
                value={selectedItems}
                onChange={setSelectedItems}
                onClose={onClose}
                {...rest}
              />
            ))}
          </MenuList>
          {options?.length - 1 !== index && <Divider />}
        </MenuCategory>
      ))}
    </Overlay.Container>
  );
};

const Menu = React.forwardRef(MenuWrapped);

Menu.displayName = 'Menu';

Menu.propTypes = {
  ...Overlay.Container.propTypes,
  /* Styles applied to the root element */
  style: stylePropType,
  /* Class applied to the root element */
  className: PropTypes.string,
  /** Options for the action */
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  options: PropTypes.arrayOf<any>(
    PropTypes.oneOfType([menuItemShape, menuCategoryShape]),
  ).isRequired,
  /** Pre selected keys */
  defaultValue: optionalPropType(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    ),
  ),
  /** Value for controlled usage */
  value: optionalPropType(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    ),
  ),
  /** Handle for item click event */
  onChange: PropTypes.func,
  /** Handle closing of the menu */
  onClose: PropTypes.func,
  /** Handle clicking a menu item */
  onClick: PropTypes.func,
  /** the width of the menu container  */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** render custom menu item */
  renderItem: PropTypes.func,
};

export default Menu as <TKey extends string | number>(
  p: MenuProps<TKey> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
