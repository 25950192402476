import React, { ReactNode, useMemo, useEffect } from 'react';
import { withSize } from 'react-sizeme';
import styled from 'styled-components';
import {
  Responsive as ResponsiveLayout,
  Layout,
  Layouts,
} from 'react-grid-layout';
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { EmptyGridState } from './layout';
import { useCardGrid } from './useCardGrid';
import { DEFAULT_GRID_SETTINGS } from './constants';
import {
  CardComponentProps,
  GridSettingsType,
  LocalCardComponents,
  OnNewlyAddedCardsFunction,
} from './types';

interface CustomizableGridProps {
  localCardComponents: LocalCardComponents;
  renderOnCardNotFound?: () => ReactNode;
  renderCardWrapper?: React.FC<CardComponentProps>;
  gridSettings?: GridSettingsType;
  onNewlyAddedCards?: OnNewlyAddedCardsFunction;
}

const CardWrapper = styled.div({
  height: 'calc(100vh - 333px - 32px - 32px)',
  minHeight: '610px',
  padding: '15px 7.5px',
});

const GridItem = styled.div({
  zIndex: '3',
});

const CustomizableGrid = ({
  localCardComponents,
  renderOnCardNotFound,
  renderCardWrapper,
  gridSettings,
  onNewlyAddedCards,
}: CustomizableGridProps) => {
  const {
    state: {
      allLayouts,
      cardSettings,
      customizeMode,
      fullPageCardId,
      fullPageMode,
      newlyAddedCards,
    },
    saveSetting,
    size,
    removeCard,
    setAllLayouts,
    toggleFullPageMode,
  } = useCardGrid();
  const DEFAULT_PROPS_FOR_CARD = useMemo(() => ({
    customizeMode,
    fullPageMode,
    removeCard,
    saveSetting,
    style: { height: '100%' },
    toggleFullPageMode,
  }), [
    customizeMode,
    fullPageMode,
    removeCard,
    saveSetting,
    toggleFullPageMode,
  ]);

  const cards = useMemo(() => {
    return allLayouts.desktop.map(({ i, x, y, w, h }) => {
      const [cardId] = i.split('_');
      const settings = cardSettings[i] || {};
      const propsForCard = {
        cardId,
        i,
        settings,
        ...DEFAULT_PROPS_FOR_CARD,
      };
      const Card = localCardComponents[cardId]?.component ||
        renderOnCardNotFound;
      const renderWrapper = renderCardWrapper || ((props) => props.children);

      return (
        <GridItem key={i} data-grid={{ h, w, x, y }} data-testid={i} id={i}>
          {renderWrapper({
            ...propsForCard,
            children: Card && <Card {...propsForCard} />,
          })}
        </GridItem>
      );
    });
  }, [
    allLayouts.desktop,
    cardSettings,
    DEFAULT_PROPS_FOR_CARD,
    localCardComponents,
    renderOnCardNotFound,
    renderCardWrapper,
  ]);

  useEffect(() => {
    onNewlyAddedCards?.(newlyAddedCards);
  }, [onNewlyAddedCards, newlyAddedCards]);

  const onLayoutChange = (_: Layout[], newLayouts: Layouts) => {
    setAllLayouts(newLayouts);
  };

  const renderFullPageCard = () => {
    const [cardId] = fullPageCardId.split('_');
    const settings = cardSettings[fullPageCardId] || {};
    const propsForCard = {
      cardId,
      i: fullPageCardId,
      settings,
      ...DEFAULT_PROPS_FOR_CARD,
    };
    const Card = localCardComponents[cardId]?.component;

    return (
      <CardWrapper>
        <Card {...propsForCard} />
      </CardWrapper>
    );
  };

  const getSettings = () => {
    const {
      breakpoints,
      cols,
      isResizable,
      margin,
      rowHeight,
      useCSSTransforms,
      draggableCancel,
      draggableHandle,
    } = gridSettings ?? {};

    return {
      breakpoints: { ...DEFAULT_GRID_SETTINGS.breakpoints, ...breakpoints },
      cols: { ...DEFAULT_GRID_SETTINGS.cols, ...cols },
      draggableCancel: draggableCancel ?? DEFAULT_GRID_SETTINGS.draggableCancel,
      draggableHandle: draggableHandle ?? DEFAULT_GRID_SETTINGS.draggableHandle,
      isResizable: isResizable ?? DEFAULT_GRID_SETTINGS.isResizable,
      margin: margin ?? DEFAULT_GRID_SETTINGS.margin,
      rowHeight: rowHeight ?? DEFAULT_GRID_SETTINGS.rowHeight,
      useCSSTransforms: useCSSTransforms ??
      DEFAULT_GRID_SETTINGS.useCSSTransforms,
    };
  }

  if (!allLayouts.desktop.length) {
    return <EmptyGridState />;
  } else if (fullPageMode) {
    return renderFullPageCard();
  } else {
    return (
      <ResponsiveLayout
        {...getSettings()}
        layouts={allLayouts}
        width={size.width as number | undefined}
        isDraggable={customizeMode}
        onLayoutChange={onLayoutChange}
      >
        {cards}
      </ResponsiveLayout>
    );
  }
};

export default withSize()(CustomizableGrid);
