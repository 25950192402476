import React, { ComponentProps } from 'react';

import PropTypes from 'prop-types';

import { IconButton } from '@adsk/alloy-react-button';
import { XIcon } from '@adsk/alloy-react-icon';
import { useLocale } from '@adsk/alloy-react-locale';
import { stylePropType } from '@adsk/alloy-react-theme';
import Tooltip from '@adsk/alloy-react-tooltip';

/**
 * Close Button.
 */
const CloseButton = React.forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof IconButton> & {
    showEscapeLabel?: boolean;
  }
>(
  (
    {
      style,
      className,
      children,
      disabled = false,
      onClick,
      showEscapeLabel = true,
      type = 'button',
      ...props
    },
    ref,
  ) => {
    const { T } = useLocale(({ locale }) => require(`./i18n/${locale}.json`));
    return (
      <IconButton
        ref={ref}
        className={className}
        style={style}
        disabled={disabled}
        onClick={onClick}
        type={type}
        {...props}
      >
        <Tooltip
          data-testid="close-button-tooltip"
          content={
            showEscapeLabel ? T.translate('CLOSE_ESC') : T.translate('CLOSE')
          }
          getTargetProps={() => {
            return {
              style: {
                height: 24,
              },
            };
          }}
        >
          <XIcon
            data-testid="close-button"
            style={{
              padding: 0,
              height: 24,
            }}
          />
        </Tooltip>
      </IconButton>
    );
  },
);

CloseButton.displayName = 'CloseButton';

CloseButton.propTypes = {
  /** BasicButton's content */
  children: PropTypes.any,
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,
  /** button html type, eg: submit */
  type: IconButton.propTypes?.type,
  /** Disable button functionality */
  disabled: PropTypes.bool,
  /** handle onclick event for button */
  onClick: PropTypes.func,
  /** Modify tooltip if Esc will be used to close */
  showEscapeLabel: PropTypes.bool,
};

export default CloseButton;
