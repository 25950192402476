// called from index.ts

export enum DensityMode {
  Low = 'density.low',
  Medium = 'density.medium',
  High = 'density.high',
  Default = Medium,
}

export const densityModes = [DensityMode.Low, DensityMode.Medium, DensityMode.High];
