import React from 'react';
import styled from 'styled-components';
import EmptyState from '@adsk/alloy-react-empty-state';
import { LinkButton } from '@adsk/alloy-react-button';
import { useTranslations } from '../../../../hooks';
import { useCardGrid } from '../../useCardGrid';

const EmptyStateContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  height: '60vh',
  justifyContent: 'center',
});

const EmptyGridState = () => {
  const { T } = useTranslations();
  const {
    state: { customizeMode },
    toggleCustomizeMode,
    toggleCardLibrary,
  } = useCardGrid();
  const handleOnAddCards = () => {
    if (!customizeMode) {
      toggleCustomizeMode();
    }
    setTimeout(toggleCardLibrary, 0);
  };

  return (
    <EmptyStateContainer>
      <EmptyState
        data-testid='empty-grid-state'
        description={T.translate('COMPONENTS.GRID.EMPTY_STATE.DESCRIPTION')}
        illustrationType={EmptyState.ILLUSTRATION_TYPES.GRAPHS_GREY}
        style={{ width: '330px' }}
        title={T.translate('COMPONENTS.GRID.EMPTY_STATE.TITLE')}
      >
        <LinkButton
          data-testid='empty-grid-button'
          onClick={handleOnAddCards}
        >
          {T.translate('COMPONENTS.GRID.EMPTY_STATE.ADD_CARDS')}
        </LinkButton>
      </EmptyState>
    </EmptyStateContainer>
  );
};

export default EmptyGridState;
