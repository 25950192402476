import React, {
  ForwardedRef,
  ForwardRefExoticComponent,
  ForwardRefRenderFunction,
  RefAttributes,
  SVGProps,
} from 'react';

import PropTypes from 'prop-types';

import { stylePropType, StylableComponent } from '@adsk/alloy-react-theme';

import { VIEWBOXES } from '../consts';
import useIconVariation, { IconVariations } from '../useIconVariation';

type SVGSVGElementProps = SVGProps<SVGSVGElement>;

export type IconProps = StylableComponent<SVGSVGElement, SVGSVGElementProps> & {
  size?: number;
  children?: SVGSVGElementProps['children'];
};

export type IconSize = {
  size?: number;
};

export type IconWithRender<T extends IconProps = IconProps> =
  ForwardRefExoticComponent<T & RefAttributes<SVGSVGElement>> & {
    renderIcon?: (props: T & RefAttributes<SVGSVGElement>) => JSX.Element;
  };

/**
 * A component for rendering single color icons as inline SVGs.
 */
const Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ style, className, children, size = 24, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        height={size}
        width={size}
        viewBox={VIEWBOXES._24}
        fill="currentColor"
        aria-hidden="true"
        css={[{ flex: 'none' }, style].filter(Boolean)}
        className={className}
        {...props}
      >
        {children}
      </svg>
    );
  },
);

Icon.displayName = 'Icon';

Icon.propTypes = {
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,
  /** Icon content, if rendering a custom SVG */
  children: PropTypes.any,
  /** Square size of icon (in pixels, defaults to 24) */
  size: PropTypes.number,
};

export const generateRenderedIcon = (
  icons: IconVariations,
  dataTestId: string,
) => {
  const RenderedIcon: ForwardRefRenderFunction<SVGSVGElement, IconSize> = (
    props: IconSize,
    ref: ForwardedRef<SVGSVGElement>,
  ) => {
    const { d, viewBox } = useIconVariation({ size: props.size, icons });
    return (
      <Icon data-testid={dataTestId} ref={ref} viewBox={viewBox} {...props}>
        <path d={d} />
      </Icon>
    );
  };

  return RenderedIcon;
};

export default Icon;
