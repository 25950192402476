import React, { ReactElement, ReactNode } from 'react';

import PropTypes from 'prop-types';

import CloseButton from '@adsk/alloy-react-close-button';
import Divider from '@adsk/alloy-react-divider';
import theme, {
  stylePropType,
  StylableComponent,
} from '@adsk/alloy-react-theme';

export type HeaderProps = Omit<StylableComponent<HTMLDivElement>, 'title'> & {
  title?: ReactNode;
  renderActions?: () => ReactElement | null;
  disableDivider?: boolean;
  onClose?: () => void;
  showEscapeLabel?: boolean;
};

const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
  (
    {
      children,
      className,
      title,
      renderActions,
      disableDivider,
      style,
      onClose,
      showEscapeLabel,
      ...props
    },
    ref,
  ) => {
    return (
      <div ref={ref} className={className} css={[style]} {...props}>
        <div
          css={[
            {
              display: 'flex',
              justifyContent: 'space-between',
              padding: theme.spacing.M,
            },
          ]}
        >
          <div
            css={[
              {
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              },
              theme.typography.heading2,
            ]}
          >
            {title}
            {renderActions?.()}
          </div>
          <CloseButton
            data-testid="Panel__headerClose"
            onClick={onClose}
            showEscapeLabel={showEscapeLabel}
          />
        </div>
        {children}
        {!disableDivider && <Divider />}
      </div>
    );
  },
);

Header.displayName = 'Header';

Header.defaultProps = {
  disableDivider: false,
};

Header.propTypes = {
  /** Styles applied to the root element */
  style: stylePropType,
  /** Header content */
  children: PropTypes.any,
  /** Class applied to the root element */
  className: PropTypes.string,
  /** Title to display in header */
  title: PropTypes.any,
  /** Render header actions */
  renderActions: PropTypes.func,
  /** Disable the Divider below the header.  Defaults to false */
  disableDivider: PropTypes.bool,
  /** Function executed upon clicking close button */
  onClose: PropTypes.func,
};

export default Header;
