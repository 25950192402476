import React, { ComponentProps, ReactElement, ReactNode } from 'react';

import PropTypes from 'prop-types';

import Divider from '@adsk/alloy-react-divider';
import theme, {
  stylePropType,
  StylableRenderPropType,
  StylableComponent,
} from '@adsk/alloy-react-theme';

import CardHeader from './CardHeader';
import { TitleSize, TITLE_SIZES } from './consts';

const DIVIDER_STYLE = {
  marginLeft: theme.spacing.M,
  width: 'calc(100% - 16px - 16px)',
};

type CardProps = StylableComponent<HTMLDivElement> & {
  title?: ReactNode;
  titleSize?: TitleSize;
  error?: boolean;
  selected?: boolean;
  renderFooter?: (props: StylableRenderPropType) => ReactElement;
  renderHeader?: (props: ComponentProps<typeof CardHeader>) => ReactElement;
  renderHeaderAction?: () => ReactElement;
};

/**
 * A panel for housing images, text and interactive elements.
 */
const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      className,
      style,
      error,
      selected,
      renderFooter,
      renderHeader = (headerProps) => <CardHeader {...headerProps} />,
      renderHeaderAction,
      title,
      titleSize,
      ...props
    },
    ref,
  ) => (
    <div
      {...props}
      ref={ref}
      className={className}
      css={[
        {
          background: theme.tokens.colors.background.primary.value,
          border: `1px solid ${theme.tokens.colors.border.subtle.value}`,
          borderRadius: theme.borderRadius.medium,
          boxShadow: theme.shadows.lowElevation,
          display: 'flex',
          flexDirection: 'column',

          ...(selected && {
            border: `1px solid ${theme.tokens.colors.background.neutral.selected.value}`,
            boxShadow: theme.halos.focus,
          }),

          ...(error && {
            border: `1px solid ${theme.tokens.colors.border.error.value}`,
          }),
        },

        style,
      ]}
    >
      {renderHeader({
        title,
        titleSize,
        style: {
          padding: `${theme.spacing.M}px ${theme.spacing.M}px ${theme.spacing.S}px`,
        },
        ...(renderHeaderAction && { children: renderHeaderAction() }),
      })}
      <Divider style={DIVIDER_STYLE} />
      <div
        css={[
          {
            height: '100%',
            overflow: 'auto',
            padding: `${theme.spacing.S}px ${theme.spacing.M}px ${
              renderFooter ? theme.spacing.S : theme.spacing.M
            }px`,
          },
        ]}
      >
        {children}
      </div>
      {renderFooter && <Divider style={DIVIDER_STYLE} />}
      {renderFooter &&
        renderFooter({
          style: {
            padding: `${theme.spacing.S}px ${theme.spacing.M}px ${theme.spacing.M}px`,
          },
        })}
    </div>
  ),
);

Card.displayName = 'Card';

Card.propTypes = {
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,
  /** Scrollable content */
  children: PropTypes.any,
  /** Toggle error state */
  error: PropTypes.bool,
  /** Render custom header content */
  renderHeader: PropTypes.func,
  /** Render right-aligned content in header */
  renderHeaderAction: PropTypes.func,
  /** Render custom footer content */
  renderFooter: PropTypes.func,
  /** Toggle selected state */
  selected: PropTypes.bool,
  /** Title text in header */
  title(props, propName: string) {
    if (
      (props['renderHeader'] === CardHeader && props[propName] === undefined) ||
      (props[propName] !== undefined && typeof props[propName] !== 'string')
    ) {
      return new Error('User did not provide a title or a renderHeader');
    }
    return null;
  },

  /** Size of title in header, defaults to 'small' */
  titleSize: PropTypes.oneOf(Object.values(CardHeader.TITLE_SIZES)),
};

export default Object.assign(Card, { TITLE_SIZES });
