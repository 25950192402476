import React, { ReactNode } from 'react';
import { CSSProp } from 'styled-components';
import AlloyCard from '@adsk/alloy-react-card';
import { useTranslations } from '../../hooks';
import HeaderActions from '../HeaderActions';
import { getDefaultHeaderItems } from './helpers';

type AlloyCardPropTypes = Omit<React.ComponentProps<typeof AlloyCard>, 'as'>;

export interface CardProps extends AlloyCardPropTypes {
  /** Card in customize mode */
  isCustomizeMode?: boolean;
  /** Card mode */
  isFullPage?: boolean;
  /** Disables the remove item */
  disableRemoveAction?: boolean;
  /** Hides default header actions */
  hideHeaderActions?: boolean;
  /** Callback when card is removed */
  onHide?: (event: React.MouseEvent<HTMLElement>) => void;
  /** Callback when expand/contract toggles */
  onToggleExpand?: (event: React.MouseEvent<HTMLElement>) => void;
  children?: ReactNode;
}

const Card = ({
  isCustomizeMode,
  isFullPage,
  onHide,
  onToggleExpand,
  children,
  disableRemoveAction,
  hideHeaderActions,
  renderHeaderAction,
  style,
  ...props
}: CardProps) => {
  const { T } = useTranslations();
  const {
    menuItems,
    quickItems,
  } = getDefaultHeaderItems(
    { 
      T,
      disableRemoveAction,
      isCustomizeMode,
      isFullPage,
      onHide,
      onToggleExpand,
    }
  );

  const getDefaultHeader = () => {
    return (
      <HeaderActions
        className='card-default-header'
        menuItems={menuItems.length ? menuItems : undefined}
        quickItems={quickItems}
      />
    );
  };

  const headerActions = renderHeaderAction || getDefaultHeader;

  const headerStyles: CSSProp = {
    '@media\
    screen\
    and (min-device-width: 1200px)\
    and (-webkit-min-device-pixel-ratio: 1),\
    screen\
    and (min-device-width: 1200px)\
    and (-webkit-min-device-pixel-ratio: 2)\
    and (min-resolution: 192dpi)': {
      '& [data-testid="header-actions"]': {
        visibility: 'hidden',
      },
      ':hover': {
        '& [data-testid="header-actions"]': {
          visibility: 'visible',
        },
      },
    },
  };

  return (
    <AlloyCard
      data-testid='wildcard'
      renderHeaderAction={!hideHeaderActions && headerActions || undefined}
      style={[headerStyles, style]}
      {...props}
    >
      {children}
    </AlloyCard>
  );
};

export default Card;
