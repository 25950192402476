import React, { forwardRef, ComponentProps } from 'react';

import Icon, { IconWithRender, generateRenderedIcon } from './Icon';
import { VIEWBOXES } from '../consts';
import { IconVariations } from '../useIconVariation';

const draw16px =
  'M12.29 4.77 9.06 8l3.23 3.23L13.47 10a.75.75 0 0 1 1.28.53V14a.75.75 0 0 1-.75.75h-3.43a.76.76 0 0 1-.69-.47.74.74 0 0 1 .16-.81l1.19-1.18L8 9.06l-3.22 3.23L6 13.47a.74.74 0 0 1 .16.81.75.75 0 0 1-.69.47H2a.76.76 0 0 1-.75-.75v-3.43A.75.75 0 0 1 2.53 10l1.19 1.19L6.94 8 3.71 4.77 2.53 6a.71.71 0 0 1-.53.17 1 1 0 0 1-.29 0 .75.75 0 0 1-.46-.7V2A.75.75 0 0 1 2 1.25h3.43a.74.74 0 0 1 .69.46.76.76 0 0 1-.12.82L4.77 3.71 8 6.94l3.23-3.23L10 2.53a.75.75 0 0 1 .53-1.28H14a.74.74 0 0 1 .75.75v3.42a.75.75 0 0 1-.46.7.93.93 0 0 1-.29 0 .73.73 0 0 1-.53-.12Z';
const draw24px =
  'M3.25,19.86V20a.66.66,0,0,0,0,.28A.75.75,0,0,1,3.25,19.86ZM4,8.75A.76.76,0,0,0,4.76,8V5.81L8.47,9.53A.75.75,0,0,0,9.53,8.47L5.81,4.75H8a.75.75,0,0,0,0-1.5H4a.72.72,0,0,0-.29.06.78.78,0,0,0-.24.16.72.72,0,0,0-.21.38V8A.76.76,0,0,0,4,8.75Zm16,6.5a.76.76,0,0,0-.75.75v2.19l-3.72-3.72a.75.75,0,0,0-1.06,0,.74.74,0,0,0,0,1h0l3.72,3.72H16a.75.75,0,0,0,0,1.5h4a.75.75,0,0,0,.53-.22.78.78,0,0,0,.16-.24.72.72,0,0,0,.06-.29V16A.77.77,0,0,0,20,15.25ZM8.52,14.47,4.8,18.19V16a.75.75,0,0,0-1.5,0v4a.66.66,0,0,0,0,.28.85.85,0,0,0,.17.25.72.72,0,0,0,.38.21H8a.75.75,0,0,0,0-1.5H5.81l3.72-3.72h.05a.75.75,0,0,0-1.06-1.06ZM20.69,3.71a.78.78,0,0,0-.4-.4A.72.72,0,0,0,20,3.25H16a.75.75,0,0,0,0,1.5h2.19L14.47,8.47a.74.74,0,0,0,0,1h0a.75.75,0,0,0,1.06,0l3.72-3.72V8a.75.75,0,0,0,1.5,0V4A.72.72,0,0,0,20.69,3.71Z';

const icons: IconVariations = {
  _16: { d: draw16px, viewBox: VIEWBOXES._16 },
  _24: { d: draw24px, viewBox: VIEWBOXES._24 },
};

const ExpandIcon = forwardRef<SVGSVGElement, ComponentProps<typeof Icon>>(
  generateRenderedIcon(icons, 'expand'),
) as IconWithRender;

ExpandIcon.displayName = 'ExpandIcon';
ExpandIcon.propTypes = Icon.propTypes;
ExpandIcon.renderIcon = (props) => <ExpandIcon {...props} />;
export default ExpandIcon;
