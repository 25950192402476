import theme from '@adsk/alloy-react-theme';

export const BADGE_VARIANTS = {
  SOLID: 'solid',
  OUTLINE: 'outline',
  LINE: 'line',
} as const;

export const BADGE_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
} as const;

const DEPRECATED_BADGE_COLORS = {
  /**
   * @deprecated Use non-color specific option
   * @example <Badge color={Badge.COLORS.OPEN} />
   * */
  BLUE: theme.colors.adskBlue500,
  /**
   * @deprecated Use non-color specific option
   * @example <Badge color={Badge.COLORS.OPEN} />
   * */
  GREEN: theme.colors.green500,
  /**
   * @deprecated Use non-color specific option
   * @example <Badge color={Badge.COLORS.OPEN} />
   * */
  RED: theme.colors.red500,
  /**
   * @deprecated Use non-color specific option
   * @example <Badge color={Badge.COLORS.OPEN} />
   * */
  PURPLE: theme.colors.purple500,
  /**
   * @deprecated Use non-color specific option
   * @example <Badge color={Badge.COLORS.OPEN} />
   * */
  YELLOW: theme.colors.yellowOrange500,
  /**
   * @deprecated Use non-color specific option
   * @example <Badge color={Badge.COLORS.OPEN} />
   * */
  GRAY: theme.colors.charcoal900,
  /**
   * @deprecated Use non-color specific option
   * @example <Badge color={Badge.COLORS.OPEN} />
   * */
  GRAY_200: theme.colors.charcoal200,
};

export const BADGE_COLORS = {
  ...DEPRECATED_BADGE_COLORS,
  DEFAULT: theme.tokens.colors.background.accent.six.bold.value,
  OPEN: theme.tokens.colors.background.open.value,
  REVIEW: theme.tokens.colors.background.review.value,
  PENDING: theme.tokens.colors.background.pending.value,
  DRAFT: theme.tokens.colors.background.draft.value,
  POSITIVE: theme.tokens.colors.background.success.value,
  NEGATIVE: theme.tokens.colors.background.error.value,
  CLOSED: theme.tokens.colors.background.closed.value,
  REMOVED: theme.tokens.colors.background.removed.value,
} as const;
