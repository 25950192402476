import React from 'react';

import PropTypes from 'prop-types';

import { variantSwitch } from '@adsk/alloy-react-helpers';
import theme, {
  stylePropType,
  StylableComponent,
} from '@adsk/alloy-react-theme';

import { BADGE_VARIANTS, BADGE_SIZES, BADGE_COLORS } from './consts';
import { Variant, Size } from './types';

type BadgeProps = StylableComponent<HTMLDivElement> & {
  variant?: Variant;
  size?: Size;
  subStatus?: string | React.ReactNode;
  stacked?: boolean;
  color?: string;
  textColor?: string;
  disabled?: boolean;
};

/**
 * A simple component that renders a Badge based on a className/style passed
 */
const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(
  (
    {
      style,
      className,
      children,
      variant = BADGE_VARIANTS.SOLID,
      size = BADGE_SIZES.MEDIUM,
      color = BADGE_COLORS.DEFAULT,
      textColor = theme.tokens.colors.text.inverse.value,
      disabled,
      subStatus,
      stacked,
      ...props
    },
    ref,
  ) => {
    const showSubStatus = variant === BADGE_VARIANTS.LINE && subStatus;

    return (
      <span
        className={className}
        ref={ref}
        css={[
          {
            padding: `${theme.spacing.XXXS}px ${theme.spacing.XS}px`,
            borderRadius: theme.borderRadius.pill,
            display: 'inline-flex',
            ...(disabled && {
              color: theme.tokens.colors.text.inactive.value,
            }),
          },
          variantSwitch({
            statement: variant,
            variants: {
              [BADGE_VARIANTS.SOLID]: {
                color: textColor,
                backgroundColor: color,
                ...(disabled && {
                  color: theme.tokens.colors.text.inactive.value,
                  backgroundColor:
                    theme.tokens.colors.background.neutral.inactive.value,
                }),
              },
              [BADGE_VARIANTS.OUTLINE]: {
                border: `1px solid ${theme.tokens.colors.border.value}`,
              },
              [BADGE_VARIANTS.LINE]: {
                display: 'flex',
                flexWrap: 'wrap',
              },
            },
          }),
          variantSwitch({
            statement: size,
            variants: {
              [BADGE_SIZES.SMALL]: {
                ...theme.typography.labelSmall,
              },
              [BADGE_SIZES.MEDIUM]: {
                ...theme.typography.labelMedium,
              },
            },
          }),
          style,
        ]}
        {...props}
      >
        {variant === BADGE_VARIANTS.LINE && (
          <span
            css={[
              {
                width: 4,
                transform: 'scaleX(0.75)',
                borderRadius: 4,
                marginRight: theme.spacing.XS,
                backgroundColor: color,
                ...(disabled && {
                  backgroundColor:
                    theme.tokens.colors.background.neutral.inactive.value,
                }),
              },
            ]}
          />
        )}
        <span
          css={[
            {
              display: 'flex',
              flexDirection: stacked ? 'column' : 'row',
            },
          ]}
        >
          {children}
          {showSubStatus && (
            <span
              css={[
                {
                  color: disabled
                    ? theme.tokens.colors.text.inactive.value
                    : theme.tokens.colors.text.secondary.value,
                },
                variantSwitch({
                  statement: stacked?.toString(),
                  variants: {
                    false: {
                      ...theme.typography.bodyMedium,
                      paddingLeft: theme.spacing.XS,
                    },
                    true: theme.typography.bodySmall,
                  },
                }),
                variantSwitch({
                  statement: size,
                  variants: {
                    [BADGE_SIZES.SMALL]: {
                      ...theme.typography.bodySmall,
                    },
                  },
                }),
              ]}
            >
              {subStatus}
            </span>
          )}
        </span>
      </span>
    );
  },
);

Badge.displayName = 'Badge';

Badge.propTypes = {
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,
  /** Badge style variant, defaults to 'solid' */
  variant: PropTypes.oneOf(Object.values(BADGE_VARIANTS)),
  /** Badge size, defaults to 'medium' */
  size: PropTypes.oneOf(Object.values(BADGE_SIZES)),
  /** Sub status to display, only in `line` variant */
  subStatus: PropTypes.any,
  /** When using subStatus and set to true, the sub status is under the label */
  stacked: PropTypes.bool,
  /** Color to apply on the background of the Badge */
  color: PropTypes.string,
  /** Color to apply on the text */
  textColor: PropTypes.string,
  /** Sets the badge in disabled state */
  disabled: PropTypes.bool,
  /** The component's children, here you should put the text */
  children: PropTypes.any,
};

export default Object.assign(Badge, {
  VARIANTS: BADGE_VARIANTS,
  COLORS: BADGE_COLORS,
  SIZES: BADGE_SIZES,
});
