import {
  CardLayout,
  CardSettings,
  InitialCardLayoutType,
} from './types';

export const buildCardSettingsFromLayout = (cardLayout: CardLayout[]) => {
  const cardSettings = cardLayout.reduce(
    (newSettings, cardLayout) => {
      newSettings[cardLayout.i] = cardLayout.settings || {};

      return newSettings;
    }, {} as CardSettings)

  return cardSettings;
}

export const formatInitialCardLayout = (layout: InitialCardLayoutType[]) => {
  return layout.map((cardLayout) => ({
    ...cardLayout,
    i: `${cardLayout.cardId}_${cardLayout.id}`,
  }))
}