import React, { ImgHTMLAttributes, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
  stylePropType,
  DetailedStylableComponent,
} from '@adsk/alloy-react-theme';

// Import illustrations separately
import _3dModels from './illustrations/3d_models.svg';
import _3dModelsGrey from './illustrations/3d_models_grey.svg';
import accHorizontalGrey from './illustrations/acc_horizontal_grey.svg';
import accHorizontalWhite from './illustrations/acc_horizontal_white.svg';
import accHorizontalWhiteNew2022 from './illustrations/acc_horizontal_white_new_2022.svg';
import accIconBlue from './illustrations/acc_icon_blue.svg';
import accMark from './illustrations/acc_mark.svg';
import accTmFullSingle from './illustrations/acc_tm_full_single.svg';
import accTmFullSingleNew2022 from './illustrations/acc_tm_full_single_new_2022.svg';
import accTmFullSingleNew2022Black from './illustrations/acc_tm_full_single_new_2022_black.svg';
import autodesk from './illustrations/autodesk.svg';
import autodeskBlack from './illustrations/autodesk_black.svg';
import buildingConstruction from './illustrations/building_construction.svg';
import buildingConstructionGrey from './illustrations/building_construction_grey.svg';
import classification from './illustrations/classification.svg';
import classificationGrey from './illustrations/classification_grey.svg';
import clipboardConfigureFields from './illustrations/clipboard_configure_fields.svg';
import clipboardConfigureFieldsGrey from './illustrations/clipboard_configure_fields_grey.svg';
import clipboardWeather from './illustrations/clipboard_weather.svg';
import clipboardWeatherGrey from './illustrations/clipboard_weather_grey.svg';
import computerCharts from './illustrations/computer_charts.svg';
import computerChartsGrey from './illustrations/computer_charts_grey.svg';
import contactBinder from './illustrations/contact_binder.svg';
import contactBinderGrey from './illustrations/contact_binder_grey.svg';
import craneConstruction from './illustrations/crane_construction.svg';
import craneConstructionGrey from './illustrations/crane_construction_grey.svg';
import desktopComputer from './illustrations/desktop_computer.svg';
import desktopComputerGrey from './illustrations/desktop_computer_grey.svg';
import envelopeOpen from './illustrations/envelope_open.svg';
import envelopeOpenGrey from './illustrations/envelope_open_grey.svg';
import filingCabinet from './illustrations/filing_cabinet.svg';
import filingCabinetGrey from './illustrations/filing_cabinet_grey.svg';
import folderEmpty from './illustrations/folder_empty.svg';
import folderEmptyGrey from './illustrations/folder_empty_grey.svg';
import folderPdfClosedFist from './illustrations/folder_pdf_closed_fist.svg';
import folderPdfClosedFistGrey from './illustrations/folder_pdf_closed_fist_grey.svg';
import folderPhoto from './illustrations/folder_photo.svg';
import folderPhotoClosedFist from './illustrations/folder_photo_closed_fist.svg';
import folderPhotoClosedFistGrey from './illustrations/folder_photo_closed_fist_grey.svg';
import folderPhotoGrey from './illustrations/folder_photo_grey.svg';
import folderSearch from './illustrations/folder_search.svg';
import folderSearchGrey from './illustrations/folder_search_grey.svg';
import folderSheetModel from './illustrations/folder_sheet_model.svg';
import folderSheetModelGrey from './illustrations/folder_sheet_model_grey.svg';
import graphs from './illustrations/graphs.svg';
import graphsGrey from './illustrations/graphs_grey.svg';
import inventory from './illustrations/inventory.svg';
import inventoryGrey from './illustrations/inventory_grey.svg';
import invoiceCheckmark from './illustrations/invoice_checkmark.svg';
import invoiceCheckmarkGrey from './illustrations/invoice_checkmark_grey.svg';
import mediaPhoto from './illustrations/media_photo.svg';
import mediaPhotoGrey from './illustrations/media_photo_grey.svg';
import nailgun from './illustrations/nailgun.svg';
import nailgunGrey from './illustrations/nailgun_grey.svg';
import pageClosedFist from './illustrations/page_closed_fist.svg';
import pageClosedFistGrey from './illustrations/page_closed_fist_grey.svg';
import pageListCheckmark from './illustrations/page_list_checkmark.svg';
import pageListCheckmarkGrey from './illustrations/page_list_checkmark_grey.svg';
import pagePhoto from './illustrations/page_photo.svg';
import pagePhotoGrey from './illustrations/page_photo_grey.svg';
import pagesPhoto from './illustrations/pages_photo.svg';
import pagesPhotoGrey from './illustrations/pages_photo_grey.svg';
import pagesQAndA from './illustrations/pages_q_and_a.svg';
import pagesQAndAGrey from './illustrations/pages_q_and_a_grey.svg';
import pagesStamps from './illustrations/pages_stamps.svg';
import pagesStampsGrey from './illustrations/pages_stamps_grey.svg';
import pagesText from './illustrations/pages_text.svg';
import pagesTextGrey from './illustrations/pages_text_grey.svg';
import pdfClosedFist from './illustrations/pdf_closed_fist.svg';
import pdfClosedFistGrey from './illustrations/pdf_closed_fist_grey.svg';
import peopleHeads from './illustrations/people_heads.svg';
import peopleHeadsGrey from './illustrations/people_heads_grey.svg';
import peopleUpperBody from './illustrations/people_upper_body.svg';
import peopleUpperBodyGrey from './illustrations/people_upper_body_grey.svg';
import pictures from './illustrations/pictures.svg';
import picturesGrey from './illustrations/pictures_grey.svg';
import roomMeasurements from './illustrations/room_measurements.svg';
import roomMeasurementsGrey from './illustrations/room_measurements_grey.svg';
import roomPackage from './illustrations/room_package.svg';
import roomPackageGrey from './illustrations/room_package_grey.svg';
import search from './illustrations/search.svg';
import searchGrey from './illustrations/search_grey.svg';
import sheet from './illustrations/sheet.svg';
import sheetAnnotations from './illustrations/sheet_annotations.svg';
import sheetAnnotationsGrey from './illustrations/sheet_annotations_grey.svg';
import sheetGrey from './illustrations/sheet_grey.svg';
import sheetMapPin from './illustrations/sheet_map_pin.svg';
import sheetMapPinGrey from './illustrations/sheet_map_pin_grey.svg';
import sheetModel from './illustrations/sheet_model.svg';
import sheetModelGrey from './illustrations/sheet_model_grey.svg';
import sheetProjection from './illustrations/sheet_projection.svg';
import sheetProjectionGrey from './illustrations/sheet_projection_grey.svg';
import sheetStampPhoto from './illustrations/sheet_stamp_photo.svg';
import sheetStampPhotoGrey from './illustrations/sheet_stamp_photo_grey.svg';
import sheetStampSnapshot from './illustrations/sheet_stamp_snapshot.svg';
import sheetStampSnapshotGrey from './illustrations/sheet_stamp_snapshot_grey.svg';
import sheetVersions from './illustrations/sheet_versions.svg';
import sheetVersionsGrey from './illustrations/sheet_versions_grey.svg';
import sheets from './illustrations/sheets.svg';
import sheetsGrey from './illustrations/sheets_grey.svg';
import skyline from './illustrations/skyline.svg';
import skylineGrey from './illustrations/skyline_grey.svg';
import speechBubbles from './illustrations/speech_bubbles.svg';
import speechBubblesGrey from './illustrations/speech_bubbles_grey.svg';
import speechBubblesInformation from './illustrations/speech_bubbles_information.svg';
import speechBubblesInformationGrey from './illustrations/speech_bubbles_information_grey.svg';
import speechBubblesPages from './illustrations/speech_bubbles_pages.svg';
import speechBubblesPagesGrey from './illustrations/speech_bubbles_pages_grey.svg';
import tablet from './illustrations/tablet.svg';
import tabletGrey from './illustrations/tablet_grey.svg';
import tabletHands from './illustrations/tablet_hands.svg';
import tabletHandsGrey from './illustrations/tablet_hands_grey.svg';
import tabletHandsSheet from './illustrations/tablet_hands_sheet.svg';
import tabletHandsSheetGrey from './illustrations/tablet_hands_sheet_grey.svg';
import toolbox from './illustrations/toolbox.svg';
import toolboxGrey from './illustrations/toolbox_grey.svg';
import toolboxPages from './illustrations/toolbox_pages.svg';
import toolboxPagesGrey from './illustrations/toolbox_pages_grey.svg';
import weatherCloudy from './illustrations/weather_cloudy.svg';
import weatherFog from './illustrations/weather_fog.svg';
import weatherPartlyCloudy from './illustrations/weather_partly_cloudy.svg';
import weatherRaindrop from './illustrations/weather_raindrop.svg';
import weatherRainy from './illustrations/weather_rainy.svg';
import weatherSleet from './illustrations/weather_sleet.svg';
import weatherSnow from './illustrations/weather_snow.svg';
import weatherSnowflake from './illustrations/weather_snowflake.svg';
import weatherSunny from './illustrations/weather_sunny.svg';
import weatherWindy from './illustrations/weather_windy.svg';
import workplace from './illustrations/workplace.svg';
import workplaceGrey from './illustrations/workplace_grey.svg';
import workplaceView from './illustrations/workplace_view.svg';
import workplaceViewGrey from './illustrations/workplace_view_grey.svg';

const ILLUSTRATIONS = {
  // Note: In order to preserve name mapping across assets and due to Javascript's
  // inability to define a variable name that starts with a number, we are using
  // string literal keys as a work around.
  '3dModels': _3dModels,
  '3dModelsGrey': _3dModelsGrey,
  accMark,
  accIconBlue,
  accHorizontalGrey,
  accHorizontalWhite,
  accHorizontalWhiteNew2022,
  accTmFullSingle,
  accTmFullSingleNew2022,
  accTmFullSingleNew2022Black,
  autodesk,
  autodeskBlack,
  buildingConstruction,
  buildingConstructionGrey,
  classification,
  classificationGrey,
  clipboardConfigureFields,
  clipboardConfigureFieldsGrey,
  clipboardWeather,
  clipboardWeatherGrey,
  computerCharts,
  computerChartsGrey,
  contactBinder,
  contactBinderGrey,
  craneConstruction,
  craneConstructionGrey,
  desktopComputer,
  desktopComputerGrey,
  envelopeOpen,
  envelopeOpenGrey,
  filingCabinet,
  filingCabinetGrey,
  folderEmpty,
  folderEmptyGrey,
  folderPdfClosedFist,
  folderPdfClosedFistGrey,
  folderPhoto,
  folderPhotoClosedFist,
  folderPhotoClosedFistGrey,
  folderPhotoGrey,
  folderSearch,
  folderSearchGrey,
  folderSheetModel,
  folderSheetModelGrey,
  graphs,
  graphsGrey,
  inventory,
  inventoryGrey,
  invoiceCheckmark,
  invoiceCheckmarkGrey,
  mediaPhoto,
  mediaPhotoGrey,
  nailgun,
  nailgunGrey,
  pageClosedFist,
  pageClosedFistGrey,
  pageListCheckmark,
  pageListCheckmarkGrey,
  pagePhoto,
  pagePhotoGrey,
  pagesPhoto,
  pagesPhotoGrey,
  pagesQAndA,
  pagesQAndAGrey,
  pagesStamps,
  pagesStampsGrey,
  pagesText,
  pagesTextGrey,
  pdfClosedFist,
  pdfClosedFistGrey,
  peopleHeads,
  peopleHeadsGrey,
  peopleUpperBody,
  peopleUpperBodyGrey,
  pictures,
  picturesGrey,
  roomMeasurements,
  roomMeasurementsGrey,
  roomPackage,
  roomPackageGrey,
  search,
  searchGrey,
  sheet,
  sheetAnnotations,
  sheetAnnotationsGrey,
  sheetGrey,
  sheetMapPin,
  sheetMapPinGrey,
  sheetModel,
  sheetModelGrey,
  sheetProjection,
  sheetProjectionGrey,
  sheetStampPhoto,
  sheetStampPhotoGrey,
  sheetStampSnapshot,
  sheetStampSnapshotGrey,
  sheetVersions,
  sheetVersionsGrey,
  sheets,
  sheetsGrey,
  skyline,
  skylineGrey,
  speechBubbles,
  speechBubblesGrey,
  speechBubblesInformation,
  speechBubblesInformationGrey,
  speechBubblesPages,
  speechBubblesPagesGrey,
  tablet,
  tabletGrey,
  tabletHands,
  tabletHandsGrey,
  tabletHandsSheet,
  tabletHandsSheetGrey,
  toolbox,
  toolboxGrey,
  toolboxPages,
  toolboxPagesGrey,
  weatherCloudy,
  weatherFog,
  weatherPartlyCloudy,
  weatherRaindrop,
  weatherRainy,
  weatherSleet,
  weatherSnow,
  weatherSnowflake,
  weatherSunny,
  weatherWindy,
  workplace,
  workplaceGrey,
  workplaceView,
  workplaceViewGrey,
} as const;

type Illustrations = typeof ILLUSTRATIONS;
type IllustrationKeys = keyof Illustrations;

type IllustrationAliasKeys =
  | 'firstUse'
  | 'noChartData'
  | 'noData'
  | 'noPermission'
  | 'noResults'
  | 'systemError'
  | 'AUTODESK_WHITE';

/**
 * @deprecated
 * Legacy illustration type mapping (deprecated, to be removed later)
 */
const ILLUSTRATION_ALIAS_TYPES: {
  [key in IllustrationAliasKeys]: IllustrationKeys;
} = {
  firstUse: 'folderEmptyGrey',
  noChartData: 'graphsGrey',
  noData: 'filingCabinetGrey',
  noPermission: 'toolboxGrey',
  noResults: 'pagesTextGrey',
  systemError: 'desktopComputerGrey',
  AUTODESK_WHITE: 'autodeskBlack',
} as const;

export const ILLUSTRATION_TYPES = {
  '3D_MODELS': '3dModels',
  '3D_MODELS_GREY': '3dModelsGrey',
  ACC_MARK: 'accMark',
  ACC_ICON_BLUE: 'accIconBlue',
  ACC_HORIZONTAL_GREY: 'accHorizontalGrey',
  ACC_HORIZONTAL_WHITE: 'accHorizontalWhite',
  ACC_HORIZONTAL_WHITE_NEW_2022: 'accHorizontalWhiteNew2022',
  ACC_TM_FULL_SINGLE: 'accTmFullSingle',
  ACC_TM_FULL_SINGLE_NEW_2022: 'accTmFullSingleNew2022',
  ACC_TM_FULL_SINGLE_NEW_2022_BLACK: 'accTmFullSingleNew2022Black',
  AUTODESK: 'autodesk',
  AUTODESK_BLACK: 'autodeskBlack',
  BUILDING_CONSTRUCTION: 'buildingConstruction',
  BUILDING_CONSTRUCTION_GREY: 'buildingConstructionGrey',
  CLASSIFICATION: 'classification',
  CLASSIFICATION_GREY: 'classificationGrey',
  CLIPBOARD_CONFIGURE_FIELDS: 'clipboardConfigureFields',
  CLIPBOARD_CONFIGURE_FIELDS_GREY: 'clipboardConfigureFieldsGrey',
  CLIPBOARD_WEATHER: 'clipboardWeather',
  CLIPBOARD_WEATHER_GREY: 'clipboardWeatherGrey',
  COMPUTER_CHARTS: 'computerCharts',
  COMPUTER_CHARTS_GREY: 'computerChartsGrey',
  CONTACT_BINDER: 'contactBinder',
  CONTACT_BINDER_GREY: 'contactBinderGrey',
  CRANE_CONSTRUCTION: 'craneConstruction',
  CRANE_CONSTRUCTION_GREY: 'craneConstructionGrey',
  DESKTOP_COMPUTER: 'desktopComputer',
  DESKTOP_COMPUTER_GREY: 'desktopComputerGrey',
  ENVELOPE_OPEN: 'envelopeOpen',
  ENVELOPE_OPEN_GREY: 'envelopeOpenGrey',
  FILING_CABINET: 'filingCabinet',
  FILING_CABINET_GREY: 'filingCabinetGrey',
  FOLDER_EMPTY: 'folderEmpty',
  FOLDER_EMPTY_GREY: 'folderEmptyGrey',
  FOLDER_PDF_CLOSED_FIST: 'folderPdfClosedFist',
  FOLDER_PDF_CLOSED_FIST_GREY: 'folderPdfClosedFistGrey',
  FOLDER_PHOTO: 'folderPhoto',
  FOLDER_PHOTO_CLOSED_FIST: 'folderPhotoClosedFist',
  FOLDER_PHOTO_CLOSED_FIST_GREY: 'folderPhotoClosedFistGrey',
  FOLDER_PHOTO_GREY: 'folderPhotoGrey',
  FOLDER_SEARCH: 'folderSearch',
  FOLDER_SEARCH_GREY: 'folderSearchGrey',
  FOLDER_SHEET_MODEL: 'folderSheetModel',
  FOLDER_SHEET_MODEL_GREY: 'folderSheetModelGrey',
  GRAPHS: 'graphs',
  GRAPHS_GREY: 'graphsGrey',
  INVENTORY: 'inventory',
  INVENTORY_GREY: 'inventoryGrey',
  INVOICE_CHECKMARK: 'invoiceCheckmark',
  INVOICE_CHECKMARK_GREY: 'invoiceCheckmarkGrey',
  MEDIA_PHOTO: 'mediaPhoto',
  MEDIA_PHOTO_GREY: 'mediaPhotoGrey',
  NAILGUN: 'nailgun',
  NAILGUN_GREY: 'nailgunGrey',
  PAGE_CLOSED_FIST: 'pageClosedFist',
  PAGE_CLOSED_FIST_GREY: 'pageClosedFistGrey',
  PAGE_LIST_CHECKMARK: 'pageListCheckmark',
  PAGE_LIST_CHECKMARK_GREY: 'pageListCheckmarkGrey',
  PAGE_PHOTO: 'pagePhoto',
  PAGE_PHOTO_GREY: 'pagePhotoGrey',
  PAGES_PHOTO: 'pagesPhoto',
  PAGES_PHOTO_GREY: 'pagesPhotoGrey',
  PAGES_Q_AND_A: 'pagesQAndA',
  PAGES_Q_AND_A_GREY: 'pagesQAndAGrey',
  PAGES_STAMPS: 'pagesStamps',
  PAGES_STAMPS_GREY: 'pagesStampsGrey',
  PAGES_TEXT: 'pagesText',
  PAGES_TEXT_GREY: 'pagesTextGrey',
  PDF_CLOSED_FIST: 'pdfClosedFist',
  PDF_CLOSED_FIST_GREY: 'pdfClosedFistGrey',
  PEOPLE_HEADS: 'peopleHeads',
  PEOPLE_HEADS_GREY: 'peopleHeadsGrey',
  PEOPLE_UPPER_BODY: 'peopleUpperBody',
  PEOPLE_UPPER_BODY_GREY: 'peopleUpperBodyGrey',
  PICTURES: 'pictures',
  PICTURES_GREY: 'picturesGrey',
  ROOM_MEASUREMENTS: 'roomMeasurements',
  ROOM_MEASUREMENTS_GREY: 'roomMeasurementsGrey',
  ROOM_PACKAGE: 'roomPackage',
  ROOM_PACKAGE_GREY: 'roomPackageGrey',
  SEARCH: 'search',
  SEARCH_GREY: 'searchGrey',
  SHEET: 'sheet',
  SHEET_ANNOTATIONS: 'sheetAnnotations',
  SHEET_ANNOTATIONS_GREY: 'sheetAnnotationsGrey',
  SHEET_GREY: 'sheetGrey',
  SHEET_MAP_PIN: 'sheetMapPin',
  SHEET_MAP_PIN_GREY: 'sheetMapPinGrey',
  SHEET_MODEL: 'sheetModel',
  SHEET_MODEL_GREY: 'sheetModelGrey',
  SHEET_PROJECTION: 'sheetProjection',
  SHEET_PROJECTION_GREY: 'sheetProjectionGrey',
  SHEET_STAMP_PHOTO: 'sheetStampPhoto',
  SHEET_STAMP_PHOTO_GREY: 'sheetStampPhotoGrey',
  SHEET_STAMP_SNAPSHOT: 'sheetStampSnapshot',
  SHEET_STAMP_SNAPSHOT_GREY: 'sheetStampSnapshotGrey',
  SHEET_VERSIONS: 'sheetVersions',
  SHEET_VERSIONS_GREY: 'sheetVersionsGrey',
  SHEETS: 'sheets',
  SHEETS_GREY: 'sheetsGrey',
  SKYLINE: 'skyline',
  SKYLINE_GREY: 'skylineGrey',
  SPEECH_BUBBLES: 'speechBubbles',
  SPEECH_BUBBLES_GREY: 'speechBubblesGrey',
  SPEECH_BUBBLES_INFORMATION: 'speechBubblesInformation',
  SPEECH_BUBBLES_INFORMATION_GREY: 'speechBubblesInformationGrey',
  SPEECH_BUBBLES_PAGES: 'speechBubblesPages',
  SPEECH_BUBBLES_PAGES_GREY: 'speechBubblesPagesGrey',
  TABLET: 'tablet',
  TABLET_GREY: 'tabletGrey',
  TABLET_HANDS: 'tabletHands',
  TABLET_HANDS_SHEET: 'tabletHandsSheet',
  TABLET_HANDS_SHEET_GREY: 'tabletHandsSheetGrey',
  TABLET_HANDS_GREY: 'tabletHandsGrey',
  TOOLBOX: 'toolbox',
  TOOLBOX_GREY: 'toolboxGrey',
  TOOLBOX_PAGES: 'toolboxPages',
  TOOLBOX_PAGES_GREY: 'toolboxPagesGrey',
  WEATHER_CLOUDY: 'weatherCloudy',
  WEATHER_FOG: 'weatherFog',
  WEATHER_PARTLY_CLOUDY: 'weatherPartlyCloudy',
  WEATHER_RAINDROP: 'weatherRaindrop',
  WEATHER_RAINY: 'weatherRainy',
  WEATHER_SLEET: 'weatherSleet',
  WEATHER_SNOW: 'weatherSnow',
  WEATHER_SNOWFLAKE: 'weatherSnowflake',
  WEATHER_SUNNY: 'weatherSunny',
  WEATHER_WINDY: 'weatherWindy',
  WORKPLACE: 'workplace',
  WORKPLACE_GREY: 'workplaceGrey',
  WORKPLACE_VIEW: 'workplaceView',
  WORKPLACE_VIEW_GREY: 'workplaceViewGrey',
  // Legacy illustration types (deprecated, to be removed later)
  FIRST_USE: 'firstUse',
  NO_CHART_DATA: 'noChartData',
  NO_DATA: 'noData',
  NO_PERMISSION: 'noPermission',
  NO_RESULTS: 'noResults',
  SYSTEM_ERROR: 'systemError',
} as const;

type IllustrationTypes = typeof ILLUSTRATION_TYPES;
export type IllustrationType = IllustrationTypes[keyof IllustrationTypes];

type IllustrationProps = DetailedStylableComponent<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  type: IllustrationType;
};

/**
 * A component for rendering multi-color images, typically vector illustrations.
 */

const Illustration = React.forwardRef<HTMLImageElement, IllustrationProps>(
  ({ style, className, type, ...props }, ref) => {
    useEffect(() => {
      if (
        Object.keys(ILLUSTRATION_ALIAS_TYPES).includes(type) &&
        ILLUSTRATION_ALIAS_TYPES[type as IllustrationAliasKeys]
      ) {
        console.warn(
          `Illustration type "${type}" is deprecated. Please update to type, "${
            ILLUSTRATION_ALIAS_TYPES[type as IllustrationAliasKeys]
          }".`,
        );
      }
    }, [type]);

    const src = Object.keys(ILLUSTRATION_ALIAS_TYPES).includes(type)
      ? ILLUSTRATIONS[ILLUSTRATION_ALIAS_TYPES[type as IllustrationAliasKeys]]
      : ILLUSTRATIONS[type as IllustrationKeys];

    return (
      <img
        {...props}
        css={[style]}
        className={className}
        ref={ref}
        data-testid={type}
        src={src}
      />
    );
  },
);

Illustration.displayName = 'Illustration';

Illustration.propTypes = {
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,
  /** Illustration type */
  type: PropTypes.oneOf(Object.values(ILLUSTRATION_TYPES)).isRequired,
  /** Illustration height */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Illustration width */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Object.assign(Illustration, { TYPES: ILLUSTRATION_TYPES });
