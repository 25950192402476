// Todo: Refactor to not duplicate themes with prefix and not prefix
// called from index.ts

// addNewTheme:  add your theme(s) here
export enum Theme {
  HigLightGray = 'hig-light-gray',
  HigDarkBlue = 'hig-dark-blue',
  HigDarkGray = 'hig-dark-gray',
  DigitalHigBrandLight = 'digital-hig-brand-light',
  DigitalHigBrandDark = 'digital-hig-brand-dark',
  Default = HigLightGray,
}

// addNewTheme:  add your theme(s) here
export enum ThemeNoPrefix {
  LightGray = 'light-gray',
  DarkBlue = 'dark-blue',
  DarkGray = 'dark-gray',
  BrandLight = 'brand-light',
  BrandDark = 'brand-dark',
  Default = LightGray,
}

// addNewTheme:  add your theme(s) here
export const themes = [
  Theme.HigLightGray,
  Theme.HigDarkBlue,
  Theme.HigDarkGray,
  Theme.DigitalHigBrandLight,
  Theme.DigitalHigBrandDark,
];

// addNewTheme:  add your theme(s) heres
export const themesNoPrefix = [
  ThemeNoPrefix.LightGray,
  ThemeNoPrefix.DarkBlue,
  ThemeNoPrefix.DarkGray,
  ThemeNoPrefix.BrandLight,
  ThemeNoPrefix.BrandDark,
];
