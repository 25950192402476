import React from 'react';

import PropTypes from 'prop-types';

import { variantSwitch } from '@adsk/alloy-react-helpers';
import theme, {
  stylePropType,
  StylableComponent,
} from '@adsk/alloy-react-theme';

import { PLACEMENTS, VARIANTS } from '../consts';
import { getMainAxisPlacement, getContainerBodyStyle } from '../helpers';
import type { ThemeVariant, Placement } from '../types';

type ContainerProps = StylableComponent<HTMLDivElement> & {
  placement?: Placement;
  variant?: ThemeVariant;
  padding?: string | number;
};

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  (
    { className, style, children, placement, variant, padding = 0, ...props },
    ref,
  ) => (
    <div
      ref={ref}
      className={className}
      css={[
        {
          position: 'absolute',
          left: 0,
          top: 0,
          margin: 0,
          right: 'auto',
          bottom: 'auto',
          zIndex: theme.zIndex.tooltip,
        },
        variantSwitch({
          statement: getMainAxisPlacement(placement),
          variants: {
            [PLACEMENTS.LEFT]: {
              padding: `0 ${padding}`,
            },
            [PLACEMENTS.RIGHT]: {
              padding: `0 ${padding}`,
            },
            [PLACEMENTS.TOP]: {
              padding: `${padding} 0`,
            },
            [PLACEMENTS.BOTTOM]: {
              padding: `${padding} 0`,
            },
          },
        }),
        getContainerBodyStyle(variant),
        style,
      ]}
      {...props}
    >
      {children}
    </div>
  ),
);

Container.displayName = 'Container';

Container.defaultProps = {
  padding: 0,
};

Container.propTypes = {
  /** className */
  className: PropTypes.string,
  /** style */
  style: stylePropType,
  /** placement */
  placement: PropTypes.oneOf(Object.values(PLACEMENTS)),
  /** children */
  children: PropTypes.any,
  /** padding */
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** variants */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

export default Object.assign(Container, { PLACEMENTS, VARIANTS });
