import React, { ReactNode } from 'react';

import PropTypes from 'prop-types';

import theme, {
  stylePropType,
  StylableComponent,
} from '@adsk/alloy-react-theme';

import { TitleSize, TITLE_SIZES } from './consts';

type CardHeaderProps = Omit<StylableComponent<HTMLDivElement>, 'title'> & {
  title?: ReactNode;
  titleSize?: TitleSize;
};

/**
 * Header with left-aligned title and right-aligned content.
 */
const CardHeader = React.forwardRef<HTMLDivElement, CardHeaderProps>(
  ({ children, className, style, title, titleSize, ...props }, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={className}
        css={[
          {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          },

          style,
        ]}
      >
        <span
          css={[
            titleSize === TITLE_SIZES.SMALL
              ? theme.typography.heading3
              : theme.typography.heading1,
          ]}
        >
          {title}
        </span>
        {children}
      </div>
    );
  },
);

CardHeader.displayName = 'CardHeader';

CardHeader.defaultProps = {
  titleSize: TITLE_SIZES.SMALL,
};

CardHeader.propTypes = {
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,
  /** Right-aligned content */
  children: PropTypes.any,
  /** Title content in card header */
  title: PropTypes.any,
  /** Size of title in header, defaults to 'small' */
  titleSize: PropTypes.oneOf(Object.values(TITLE_SIZES)),
};

export default Object.assign(CardHeader, { TITLE_SIZES });
