import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { Layouts } from 'react-grid-layout';
import { buildCardSettingsFromLayout } from './helpers';
import {
  CardConfig,
  CardLayout,
  CardSetting,
  CardSettings,
  CardData,
  CardsData,
} from './types';

export interface CardGridState {
  allLayouts: Layouts;
  availableCards: CardConfig[];
  cardLibraryOpen: boolean;
  cardSettings: CardSettings;
  cardsState: CardsData;
  customizeMode: boolean;
  fullPageCardId: string;
  fullPageMode: boolean;
  initialDesktopLayout: CardLayout[];
  newlyAddedCards: CardLayout[];
}

export const initialCardGridState: CardGridState = {
  allLayouts: {},
  availableCards: [],
  cardLibraryOpen: false,
  cardSettings: {},
  cardsState: {},
  customizeMode: false,
  fullPageCardId: '',
  fullPageMode: false,
  initialDesktopLayout: [],
  newlyAddedCards: [],
};

// Taken from: https://stackoverflow.com/a/64582352/19261218
// Allows us to build a union of the action types generated from createSlice
type SliceActions<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in keyof T]: T[K] extends (...args: any[]) => infer A ? A : never;
}[keyof T]

const cardGridSlice = createSlice({
  initialState: initialCardGridState,
  name: 'cardGridSlice',
  reducers: {
    addCards: {
      prepare: (payload: CardConfig[]) => {
        const layout: CardLayout[] = payload.map((card) => {
          const id = nanoid();
          const { cardId } = card;

          return {
            cardId,
            h: card.h,
            i: `${cardId}_${id}`,
            id,
            w: card.w,
            x: 0,
            y: Infinity,
          };
        });

        return { payload: layout };
      },
      reducer: (state, { payload }: PayloadAction<CardLayout[]>) => {
        const newDesktopLayout = [...state.allLayouts.desktop, ...payload];

        state.allLayouts.desktop = newDesktopLayout;
        state.newlyAddedCards = [...payload];
      },
    },
    removeCard: (state, { payload }: PayloadAction<string>) => {
      const newDesktopLayout = state.allLayouts.desktop.filter(
        ({ i }) => i !== payload
      );
      if (state.cardSettings[payload]) {
        delete state.cardSettings[payload];
      }

      state.allLayouts.desktop = newDesktopLayout;
    },
    resetCurrentLayout: (state) => {
      state.allLayouts = { desktop: state.initialDesktopLayout }
      state.newlyAddedCards = [];
      const resetCardSettings = buildCardSettingsFromLayout(
        state.initialDesktopLayout
      );
      state.cardSettings = resetCardSettings;
    },
    saveSetting: (state, { payload }: PayloadAction<CardSetting>) => {
      const { i, key, value } = payload;
      if (!state.cardSettings[i]) {
        state.cardSettings[i] = {}
      }

      state.cardSettings[i][key] = value;
    },
    setAllLayouts: (state, { payload }: PayloadAction<Layouts>) => {
      state.allLayouts = payload;
    },
    setCardState: (state, { payload }: PayloadAction<CardData>) => {
      const { i, key, value } = payload;
      if (!state.cardsState[i]) {
        state.cardsState[i] = {}
      }

      state.cardsState[i][key] = value;
    },
    setInitialDesktopLayout: (
      state,
      { payload }: PayloadAction<CardLayout[]>
    ) => {
      state.initialDesktopLayout = payload;
      state.cardSettings = buildCardSettingsFromLayout(payload);
    },
    toggleCardLibrary: (state) => {
      state.cardLibraryOpen = !state.cardLibraryOpen;
    },
    toggleCustomizeMode: (state) => {
      state.customizeMode = !state.customizeMode;
      state.fullPageMode = false;
      state.fullPageCardId = '';
    },
    toggleFullPageMode: (state, { payload }: PayloadAction<string>) => {
      const isExitingFullscreenMode = state.fullPageCardId === payload;

      state.fullPageMode = !state.fullPageMode;
      state.fullPageCardId = isExitingFullscreenMode ? '' : payload;
    },
  },
});

export const { actions, reducer } = cardGridSlice;
export type CardGridActionTypes = SliceActions<typeof actions>
